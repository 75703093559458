import { useEffect, useState } from 'react';
import * as React from 'react';
import { Router, Route, Navigate } from "react-router-dom";
import { FrontEndPoints, MapPath } from '../shared/Common';
import { useAuthDispatch } from '../context/Context';
import { logout } from '../context/Actions';

const Logout = (props: any) => {
    const dispatch = useAuthDispatch();

    useEffect(() => {
        logout(dispatch);
    }, []);

    return (<Navigate to={FrontEndPoints.login} />);
}

export default Logout;