import * as React from 'react';
import './JasperMapping.scss';
import * as Api from '../shared/Api';
import { DknToggle, DknToggleProps } from '../dkn-toggle/DknToggle';
import { DknSelect, DknSelectValue } from '../dkn-select/DknSelect';
import { ApplicationType, MappingMode } from '../smartstore/mappings/SmartstoreMappings';
import DknHeading from '../dkn-heading/DknHeading';
import DknBtn from '../dkn-btn/DknBtn';
import DknInput from '../dkn-input/DknInput';

const JasperMapping = (props: any) => {
    const initialValues = { jasperName: "", organizationId: "", userName: "", password: "", ssoUserName: "" };
    const [formVals, setFormVals] = React.useState(initialValues);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [jasperData, setJasperData] = React.useState({} as Array<DknSelectValue>);
    const [userData, setUserData] = React.useState({} as Array<DknSelectValue>);
    const [selectedToggle, setSelectedToggle] = React.useState(MappingMode.Add) as any;
    const [userEmail, setUserEmail] = React.useState("");
    const [jasperAppName, setJasperAppName] = React.useState("");

    React.useEffect(() => {
        Api.post("home/getApplicationNames", { appType: ApplicationType.Jasper }).then(res => {
            if (res.status === 200) {
                var data = res.data.map((d: any) => {
                    return {
                        value: d,
                        label: d
                    } as DknSelectValue
                }) as Array<DknSelectValue>;
                setJasperData(data);
            } else {
                setError("Jasper applications couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => setError("Jasper applications couldn't be loaded, try refreshing again or contact administrator"));

        Api.get("Home/GetJasperUsers").then(res => {
            if (res.status === 200) {
                setUserData(res.data.map((d: any) => {
                    return {
                        value: d.value,
                        label: d.label
                    }
                }));
            } else {
                setError("users couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => setError("users couldn't be loaded, try refreshing again or contact administrator"));

    }, []);

    const submitJasperMapping = (e: any) => {

        if (selectedToggle == MappingMode.Edit) {
            updateStore(e);
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        setError("");
        setSuccess("");
        setLoading(true);
        var postData = { ...formVals, jasperName: jasperAppName };

        Api.post("home/addJasper", postData).then(response => {
            setLoading(false);
            if (response.status == 200) {
                setSuccess("Mapping added successfully");
                setFormVals(initialValues);
            } else {
                setError("mapping couldn't be added, try again or contact administrator");
            }
        }, err => {
            setLoading(false);
            if (err.response.status == 409) {
                setError("One mapping already exists for this user");
            } else {
                setError("mapping couldn't be added, try again or contact administrator");
            }
        });

    }

    const onJasperSelected = (selectedApp: DknSelectValue) => {
        setJasperAppName(selectedApp.value);

        if (userEmail.length && selectedApp.value && selectedApp.value.length) {
            LoadSelectedMapping(selectedApp.value, userEmail);
        }
    }

    const onUserSelected = (selectedUser: DknSelectValue) => {
        setUserEmail(selectedUser.value);

        if (jasperAppName.length && selectedUser.value && selectedUser.value.length) {
            LoadSelectedMapping(jasperAppName, selectedUser.value);
        }
    }

    const dknToggleProps: DknToggleProps = {
        radios: [{ name: 'Add', value: MappingMode.Add }, { name: 'Edit', value: MappingMode.Edit }],
        changeFun: (val: string): void => {
            setSelectedToggle(val);
            setFormVals(initialValues);
            setUserEmail("");
            setJasperAppName("");
            setError("");
            setSuccess("");
        }
    }

    const shouldShow = (): boolean => {
        if (selectedToggle == MappingMode.Add) {
            return true;
        } else if (selectedToggle == MappingMode.Edit && jasperAppName.length > 0 && userEmail.length > 0) {
            return true;
        }
        return false;
    }

    const LoadSelectedMapping = (applicationName: string, selectedUserEmail: string) => {
        const postValues = { label: selectedUserEmail, value: applicationName };
        Api.post("Home/LoadSelectedSmartStore", postValues).then(response => {
            if (response.status == 200) {
                setLoading(false);
                setError("");
                setSuccess("");
                setFormVals({ ...formVals, organizationId: response.data.companyId, userName: response.data.user, password: response.data.userPassword });
            } else {
                setFormVals(initialValues);
                setLoading(false);
                setSuccess("");
                setError("Error in loading Jasper mapping for selected user");
            }
        }, err => {
            setFormVals(initialValues);
            setLoading(false);
            setSuccess("");
            setError("Error in loading Jasper mapping for selected user");
        });
    }

    const updateStore = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        var postData: any = { ...formVals, ssoUserName: userEmail, jasperName: jasperAppName };

        Api.post("Home/UpdateJasper", postData).then(response => {
            if (response.status == 200) {
                setLoading(false);
                setError("");
                setSuccess("Jasper mapping updated!");
                setUserEmail("");
                setJasperAppName("");
                setFormVals(initialValues);
            } else {
                setLoading(false);
                setSuccess("");
                setError("Error in updating Jasper mapping");
            }
        }, err => {
            setLoading(false);
            setSuccess("");
            setError("Error in updating Jasper mapping");
        });
    }

    return (
        <div className="jasper-mapping">

            <DknHeading title="Reporting registration" description="Add the Jasper Reporting mappings for a user here." />

            <div className="action-buttons">
                <div className="toggle-button">
                    <DknToggle {...dknToggleProps} />
                </div>
            </div>

            {success.length > 0 && <div className="api-success-text center">{success}</div>}
            {error.length > 0 && <div className="api-error-text center">{error}</div>}


            <form onSubmit={submitJasperMapping} >

                <DknSelect data={jasperData} onSelected={onJasperSelected} label="Jasper Server instance name" />
                {selectedToggle == MappingMode.Edit && <>
                    <DknSelect data={userData} onSelected={onUserSelected} label="Username" />
                </>}
                {shouldShow() && <>

                    {selectedToggle == MappingMode.Add &&
                        <DknInput dknId="orgId" dknLabel="Jasper organization id" dknPlaceholder="TEST" changeFunc={val => setFormVals({ ...formVals, organizationId: val })} dknType="text" value={formVals.organizationId} />}


                    <DknInput dknId="ssoUserName" dknLabel="SSO username" dknPlaceholder="martin.hoyem@dekon.no" changeFunc={val => setFormVals({ ...formVals, ssoUserName: val })} dknType="text" value={formVals.ssoUserName} />

                    <DknInput dknId="jasperUserName" dknLabel="Jasper username" dknPlaceholder="test" changeFunc={val => setFormVals({ ...formVals, userName: val })} dknType="text" value={formVals.userName} />

                    <DknInput dknId="password" dknLabel="Jasper password" dknPlaceholder="password" changeFunc={val => setFormVals({ ...formVals, password: val })} dknType="password" value={formVals.password} />

                    {selectedToggle == MappingMode.Add ? <div style={{ marginTop: "16px" }}><DknBtn loading={loading} disabled={loading} title="Register" type="submit" /></div> : <div style={{ marginTop: "16px" }}><DknBtn loading={loading} disabled={loading} title="Update" type="submit" /></div>}

                    
                    
                </>}
            </form>
        </div>
    );
}
export default JasperMapping;