import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FrontEndPoints, GetChangePasswordFeedback } from '../shared/Common';
import * as Api from '../shared/Api';
import './ConfirmEmail.scss';
import history from "../shared/history";

const ConfirmEmail = (props: any) => {

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isPasswordChanged, setIsPasswordChanged] = useState("");
    const [isError, setIsError] = useState("");
    const [loading, setLoading] = useState(false);

    const changePassword = (): void => {

        if (!password.length || !repeatPassword.length || password !== repeatPassword) {
            setIsError("please set password correctly!");
            return;
        }
        setLoading(true);
        setIsError("");

        var searchParams = new URLSearchParams(window.location.search);

        Api.post("activeDirectory/changePassword", { password: password, repeatPassword: repeatPassword, email: searchParams.get("email"), EmailToken: searchParams.get("emailToken") }).then(response => {
            setLoading(false);
            setIsError("");
            setIsPasswordChanged(GetChangePasswordFeedback(response.status));           
        }, err => {
                setLoading(false);               
                setIsPasswordChanged("");
                setIsError(GetChangePasswordFeedback(err.response.status));
        });
    }

    return (
        <div className="change-password">
            {isError && <h6 className="password-error">{isError}</h6>}
            <div className="form-group">
            <label className="label" htmlFor="password">Password</label>
            <input type="password" value={password} onChange={event => setPassword(event.target.value)} className="form-control user-input" id="password" aria-describedby="password" placeholder="******" />
        </div>

            <div className="form-group">
                <label htmlFor="repeatPassword">Confirm Password</label>
                <input type="password" value={repeatPassword} onChange={event => setRepeatPassword(event.target.value)} className="form-control user-input" id="repeatPassword" placeholder="******" aria-describedby="repeatPassword" />
            </div>
            <button className="btn btn-dkn" disabled={loading} onClick={() => changePassword()}>
                {loading ? <span className="saving"><span>.</span><span>.</span><span>.</span></span> :
                    "Set password"}
                </button>
            { isPasswordChanged.length > 0 &&
                <><h6 className="password-success">{isPasswordChanged}</h6>
                    <Link to={FrontEndPoints.login}>Go to Login page</Link>
                </>}            
        </div>
        );
}

export default ConfirmEmail;