import * as React from 'react';
import { Component, forwardRef, useImperativeHandle } from 'react';
import './DknModal.scss';
import { Modal, Col, Form, Card } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import DknBtn from '../dkn-btn/DknBtn';

export interface DknModalProps {
    description: string,
    title: string,
    confirmText?: string,
    cancelText?: string,
    confirmFunc?: () => void,
    cancelFunc?: () => void,
    hideButtons?: boolean;
    centered?: boolean
}

const DknModal = forwardRef(({ description, title, confirmText, cancelText, confirmFunc, cancelFunc, hideButtons, centered }: DknModalProps, ref) => {

    const [show, setShow] = React.useState(false);
    const [modelCentered, setModelCentered] = React.useState(false);
    const [hideActionButtons, setHideActionButtons] = React.useState(false);

    const handleConfirm = () => {
        setShow(false);
        confirmFunc && confirmFunc();
    }

    const handleCancel = () => {
        setShow(false);
        cancelFunc && cancelFunc();
    }

    React.useEffect(() => {
        if (hideButtons != hideActionButtons) {
            setHideActionButtons(hideButtons as boolean);
        }
        if (centered != modelCentered) {
            setModelCentered(centered as boolean);
        }
    }, [hideButtons, centered]);

    useImperativeHandle(
        ref,
        () => ({
            handleShowModal() {
                setShow(true);
            }
        }),
    )

    return (
        <Modal show={show} onHide={handleCancel} backdrop="static" centered={modelCentered}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{description}</Modal.Body>
            {!hideActionButtons && <Modal.Footer>
                {confirmText && confirmText.length > 0 && <DknBtn styles={{ backgroundColor: '#007bff'}} title={confirmText} clickFunc={handleConfirm} type="button" disabled={false} loading={false} />}

                {cancelText && cancelText.length > 0 && <DknBtn title={cancelText} clickFunc={handleCancel} type="button" disabled={false} loading={false} />}
            </Modal.Footer>}
        </Modal>
    );
})

export default DknModal;