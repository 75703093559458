import * as React from 'react';
import { Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthState } from '../context/Context';
import { FrontEndPoints, ValidateIsAdmin } from './Common';

const AdminRoute = () => {

    const userDetails: any = useAuthState();
    const adm = ValidateIsAdmin(userDetails);

    return adm ? <Outlet /> : <Navigate to={FrontEndPoints.activeDirectory} />;
}

export default AdminRoute;