import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import './AuthOptions.scss';
import * as Api from '../shared/Api';
import { saveState } from '../shared/LocalStorage';
import * as React from 'react';
import { AuthType, GetAuthError, GetUserStepCaption, LoginEndPoints, UserStep, FrontEndPoints, GetEndpoint, FrontEndRoles } from '../shared/Common';
import history from '../shared/history';
import { useAuthDispatch, useAuthState } from '../context/Context';
import { loginUser } from '../context/Actions';
import ReCAPTCHA from 'react-google-recaptcha';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DknBtn from '../dkn-btn/DknBtn';
import DknHeading from '../dkn-heading/DknHeading';

export const AuthOptions = (props: any) => {

    const [verifyEmailCode, setVerifyEmailCode] = useState(false);
    const [verifyCodeError, setVerifyCodeError] = useState("");
    const [codeVerified, setCodeVerified] = useState(false);
    const [inputOptCode, setInputOptCode] = useState('');
    const [selectedOption, setSelectedOption] = useState(AuthType.authy);
    const [informationCaption, setInformationCaption] = useState("");
    const [loading, setLoading] = useState(false);
    const [alphaNumericKey, setAlphaNumericKey] = useState("");

    const [endPoint, setEndPoint] = useState(GetEndpoint("login"));

    const dispatch = useAuthDispatch();
    const [failCount, setFailCount] = useState(0);
    const [captchaValue, setCaptchaValue] = useState("");
    const [capRefObj, setCapRefObj] = useState() as any;
    const [isTrusted, setIsTrusted] = useState(false);

    const limit = 5;

    const userDetails: any = useAuthState();

    const continueLoginUser = () => {
        setLoading(true);
        setVerifyCodeError("");

        Api.post(endPoint.optCodeEndpoint, { optCode: inputOptCode }).then(response => {
            setLoading(true);
            if (response && response.status == 200) {
                saveState(response.data);
                loginUser(dispatch, response.data);
                setCodeVerified(true);
            } else {
                setVerifyCodeError("Error has occurred, please try again or contact administrator.");
                setFailCount(response.data.failCount);
                capRefObj.props.grecaptcha.reset();
                setCaptchaValue("");
            }
        }, err => {
            setLoading(false);
            setVerifyCodeError("Error has occurred, please try again or contact administrator.");
            setFailCount(err.response.data.failCount);
            capRefObj.props.grecaptcha.reset();
            setCaptchaValue("");
        });
    };

    const emailContinue = (e: any) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();

        setAlphaNumericKey("");

        setLoading(true);
        Api.get(endPoint.emailEndpoint).then(response => {
            setLoading(false);
            if (response && response.status == 200) {
                setVerifyEmailCode(true);
            } else {
                setVerifyCodeError(GetAuthError(response.status));
            }
        }, err => {
            setLoading(false);
            setVerifyCodeError("Error has occurred, please try again or contact administrator.")
        });
    }

    const allAlphaNumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const makeAphaNumericKey = (length: number = 8) => {
        var result = '';
        var charactersLength = allAlphaNumeric.length;
        for (var i = 0; i < length; i++) {
            result += allAlphaNumeric.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const authyContinue = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        var aphaNumericString = makeAphaNumericKey();
        setAlphaNumericKey(aphaNumericString);

        setLoading(true);
        setInformationCaption(GetUserStepCaption(UserStep.authyWaiting));

        Api.post(endPoint.authyEndpoint, { AlphaNumericString: aphaNumericString, IsTrusted: isTrusted }).then(response => {
            setLoading(false);
            setInformationCaption(GetUserStepCaption(UserStep.none));
            if (response && response.status == 200) {
                loginUser(dispatch, response.data);
                saveState(response.data);
                history.push(FrontEndPoints.all);
                setCodeVerified(true);
            } else {
                setVerifyCodeError(GetAuthError(response.status));
            }
        }, err => {
            setLoading(false);
            setVerifyCodeError("Error has occurred, please try again or contact administrator.")
        });
    }

    useEffect(() => {

        if (props && props.authyEndpoint && props.authyEndpoint.length) {
            setEndPoint(props);
        }

        Api.get("login/LoginEmailErrorCount").then(response => {
            setFailCount(response.data);
        });

        if (userDetails.roles.includes(FrontEndRoles.TemporaryUser)) {
            setIsTrusted(true);
        }

    }, []);

    const onChange = (value: any) => {
        setCaptchaValue(value);
    }

    if (codeVerified) {
        return <Navigate to={FrontEndPoints.all} />;
    }
    return (
        <div className="auth-options">
            <div className="row">
                <div className="col-sm-12">
                    <DknHeading title="Dekon SSO 2FA confirmation" description="Choose the 2FA option, Email option is not recommended." />
                </div>
            </div>

            {!verifyEmailCode ? <div className="step-one">
                <form onSubmit={(e: any) => selectedOption == AuthType.authy ? authyContinue(e) : emailContinue(e)}>
                    <div className="options" onChange={(event: any) => setSelectedOption(event.target.value)}>
                        <div className="form-check">
                            <input className="form-check-input" tabIndex={1} type="radio" value={AuthType.authy} name="twoFaOption" id="authyOption" checked />
                            <label className="form-check-label" htmlFor="authyOption">Authy app (recommended)</label>
                        </div>
                        {informationCaption.length > 0 && <><h6 className="label-info">{informationCaption}</h6>
                            <h6>Authy App key: <span className="alphanumeric-key"> {alphaNumericKey} </span></h6></>}

                        <div className="form-check">
                            <input className="form-check-input" type="radio" tabIndex={2} value={AuthType.email} name="twoFaOption" id="emailOption" />
                            <label className="form-check-label" htmlFor="emailOption">Email (not recommended)</label>
                        </div>
                    </div>

                    <div className="checkbox remember-me">
                        <label htmlFor="rememberMe">
                            <input type="checkbox" value="" checked={isTrusted} onChange={event => setIsTrusted(event.target.checked)} id="rememberMe" />&nbsp; Remember me on this device</label>
                        <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                                <Tooltip id='tooltip-bottom'>
                                    Enable "Remember me" if you trust the device you are using. Enabling "Remember me" prevent the need for confirm with 2nd factor every time you login in from the same device. However, if you connect your device to another network or change your browser, you must confirm again. You will also need to confirm 2nd factor on a regular basis.
                                </Tooltip>
                            }
                        >
                            <img src="question-mark.svg" alt="?" className="dekon-icon question-icon" />
                        </OverlayTrigger>
                    </div>

                    {verifyCodeError.length > 0 && <h5 className="verify-code-error">{verifyCodeError}</h5>}

                    <div style={{ marginTop: "16px" }}>
                        <DknBtn title="Login" type="submit" loading={loading} disabled={loading} />
                    </div>
                </form>
            </div> :
                <div className="step-two">
                    <h6 className="feedback">{GetUserStepCaption(UserStep.emailCodeInput)}</h6>
                    <div className="form-group">
                        <label htmlFor="optCode">Code</label>
                        <input type="password" value={inputOptCode} onChange={event => setInputOptCode(event.target.value)} className="form-control user-input" id="optCode" placeholder="123455" />
                        {verifyCodeError.length > 0 && <h5 className="verify-code-error">{verifyCodeError}
                            {failCount <= limit && <span className="resend-email" onClick={(e: any) => continueLoginUser()}>resend email</span>}
                        </h5>}
                    </div>

                    <div className="captcha-parent" style={failCount > limit ? {} : { display: 'none' }}>
                        <ReCAPTCHA ref={el => { setCapRefObj(el); }} sitekey="6Les4ioaAAAAAAE027GKw8fHo8-7doFMhfZ1FMIX" onChange={onChange} />
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <DknBtn title="Login" type="button" clickFunc={continueLoginUser} loading={loading} disabled={loading || (failCount > limit && captchaValue != undefined && captchaValue.length == 0)} />
                    </div>
                </div>}
        </div>
    );

}