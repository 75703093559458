import * as React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from '../context/Context';
import { FrontEndPoints, ValidateTemperoryUser } from './Common';

const TemporaryRoute = ({ component: Component, ...rest }: any) => {

    const userDetails: any = useAuthState();

                var isValid = ValidateTemperoryUser(userDetails);
                if (isValid == true) {
                    return (<Outlet />);
                } else {
                    return <Navigate to={ FrontEndPoints.all } />;
                }
}

export default TemporaryRoute;