import React from 'react';
import { useState, useEffect } from 'react';
import './DknBtn.scss';
import { Spinner } from 'reactstrap';
import { DknDeleteIcon, DknLeftArrowIcon } from '../dkn-icons/DknIcons';

export interface DknBtnModel {
    title: string;
    icon?: string;
    loading?: boolean;
    disabled?: boolean;
    isDelete?: boolean;
    clickFunc?: (event: any) => void;
    styles?: React.CSSProperties
    type: "button" | "submit" | "reset";
}

const DknBtn = ({ title, icon, loading, disabled, isDelete, clickFunc, type, styles }: DknBtnModel) => {

    const [btnLoading, setBtnLoading] = useState(loading);
    const [btnDisabled, setBtnDisabled] = useState(loading);
    const [btnDelete, setBtnDelete] = useState(isDelete);

    useEffect(() => {
        if (btnLoading != loading) {
            setBtnLoading(loading);
        }
        if (btnDisabled != disabled) {
            setBtnDisabled(disabled);
        }
        if (btnDelete != isDelete) {
            setBtnDelete(isDelete);
        }
    }, [loading, disabled, isDelete]);

    return (
        <button type={type} style={styles} className={`dkn-button ${btnDelete ? "btn-del" : ""}`} onClick={clickFunc} disabled={btnDisabled}>
            {title} &nbsp;
            {btnLoading ? <Spinner
                className="dkn-spinner"
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            /> : isDelete ? <DknDeleteIcon width="15px" height="15px" /> : < DknLeftArrowIcon width="21px" height="21px" />
            }
        </button>
    )
}

export default DknBtn;