import React from 'react';
import { useState, useEffect } from 'react';
import './ApiToken.scss';
import * as Api from '../shared/Api';

import DknHeading from '../dkn-heading/DknHeading';
import DknInput from '../dkn-input/DknInput';
import { DknSelect } from '../dkn-select/DknSelect';
import DknBtn from '../dkn-btn/DknBtn';
import { forEachChild } from 'typescript';


const ApiToken = (props: any) => {

    const [formData, setFormData] = React.useState({}) as any;
    const [loading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState("");
    const [buttonText, setButtonText] = React.useState("Copy");

    const [connectionStringData, setConnectionStringData] = React.useState() as any;

    useEffect(() => {
        setLoading(true);
        if (!connectionStringData || !connectionStringData.length) {

            Api.get("apiToken").then((success: any) => {

                var mapped = success.data.map((d: any) => { return { label: d, value: d } });
                setConnectionStringData(mapped);
                setLoading(false);
            }, err => {
                setLoading(false);
            });
        }
    }, []);

    const getToken = (e: any) => {

        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        setToken("");
        setButtonText("Copy");

        Api.post("apiToken", formData).then((success: any) => {

            setToken(success.data);
            setLoading(false);
            setFormData({ ...formData, email: "" });
            console.log(success.data);
        }, err => {
            setToken("");
            setLoading(false);
        });
    };

    const selectText = () => {
        const containerid = "accessToken";
        var range: any;
        if ((document as any).selection) { // IE
            range = (document as any).body.createTextRange();
            range.moveToElementText(document.getElementById(containerid));
            range.select();
        } else if ((window as any).getSelection) {
            range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            (window as any).getSelection().removeAllRanges();
            (window as any).getSelection().addRange(range);
        }
        navigator.clipboard.writeText(range.toString().trim()); // not copying
        setButtonText("copied!");
    }

    return (
        <div className="api-token">
            <DknHeading title="Generate Tokens" description="Generate tokens for the api user for lifetime. Keep in mind that these token can access the API, so they have to be communicated/transferred securely." />

            <form onSubmit={getToken} >

                <DknInput dknId="email" dknLabel="Email" dknPlaceholder="Enter email" changeFunc={(val: any) => { setFormData({ ...formData, email: val }) }} dknType="text" value={formData.email} />

                <DknSelect data={connectionStringData} onSelected={(selected: any) => setFormData({ ...formData, connectionString: selected.value })} label="Connection string:" />

                <DknBtn title="Generate" type="submit" disabled={loading || (!formData || !formData?.email?.length || !formData?.connectionString?.length)} loading={loading} />
            </form>

            {token.length > 0 && <div className="token-container" contentEditable="false">
                {/*<button type="button" className="copy-tkn" onClick={selectText}>{buttonText}</button>*/}
                <span id="accessToken"> {token} </span>
            </div>}

        </div>

    )
}

export default ApiToken;