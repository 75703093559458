import * as React from 'react';
import './DknInput.scss';

export interface DknInputProps {
    dknId: string,
    dknLabel?: string,
    dknType: string,
    dknPlaceholder?: string,
    changeFunc: (value: string, evt?: any) => void,
    value?: string
}

const DknInput = ({ dknId, dknLabel, dknType, dknPlaceholder, changeFunc, value }: DknInputProps) => {

    const [val, setVal] = React.useState(value);

    React.useEffect(() => {
        setVal(value);
    }, [value]);

    const valueChanged = (event: any) => {
        setVal(event.target.value);
        changeFunc && changeFunc(event.target.value, event);
    }

    return (
        <>
            <div className="dkn-input-controls">
                <p className="dkn-label">{dknLabel}</p>
                <input type={dknType} id={dknId} className="dkn-input" placeholder={dknPlaceholder} onChange={valueChanged} value={val} />
                <span className="dkn-line"></span>
            </div>
        </>
    );
}

export default DknInput;