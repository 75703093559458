import React, { useEffect, useRef, useState } from 'react';
import './Register.scss';
import * as Api from '../shared/Api';
import { ErrorCodes, GetLoginError, GetUserStepCaption, GetValidationError, UserStep, ValidationErrorCodeType } from '../shared/Common';
import history from '../shared/history';
import { FrontEndPoints } from '../shared/Common';
import { DknSelect, DknSelectValue } from '../dkn-select/DknSelect';
import { DknToggle, DknToggleProps } from '../dkn-toggle/DknToggle';
import { MappingMode } from '../smartstore/mappings/SmartstoreMappings';
import DknModal from '../dkn-modal/DknModal';
import DknInput from '../dkn-input/DknInput';
import DknBtn from '../dkn-btn/DknBtn';
import DknHeading from '../dkn-heading/DknHeading';

const Register = (props: any) => {

    const [registerError, setRegisterError] = useState(false);
    const [errorString, setErrorString] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [countryCode, setcountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [office24SevenCustomerId, setOffice24SevenCustomerId] = useState() as any;
    const [loading, setLoading] = useState(false);
    const [sendMeEmail, setSendMeEmail] = useState(false);
    const [customerData, setCustomerData] = useState() as any;
    const [selectedToggle, setSelectedToggle] = React.useState(MappingMode.Add) as any;
    const [savedUserData, setSavedUserData] = React.useState() as any;

    const [isRegistered, setIsRegistered] = useState("");
    const [defaultSelectedVal, setDefaultSelectedVal] = useState() as any;
    const [defaultSelectedUserVal, setDefaultSelectedUserVal] = useState() as any;
    const [userData, setUserData] = React.useState({} as Array<DknSelectValue>);
    const registerPath = "activeDirectory/Register";
    const editPath = "activeDirectory/Edit";
    const deletePath = "activeDirectory/Delete";
    const emptyUserVal = { label: "---", value: "" };
    const childRef: any = useRef();

    const SetupError = (errString: string) => {
        setErrorString(errString);
        setIsRegistered("");
        setRegisterError(true);
    }

    const onUserSelected = (selectedUser: DknSelectValue) => {
        setIsRegistered("");
        var usersSelected = savedUserData.filter((d: any) => d.email == selectedUser.value);
        if (usersSelected && usersSelected.length) {
            fillFormValues(usersSelected[0]);
        }
    }

    useEffect(() => {
        history.push(FrontEndPoints.register);

        Api.get("Home/GetAllUsers").then(res => {
            if (res.status === 200) {

                setSavedUserData([...res.data]);
                var newData = res.data.map((d: any) => {
                    return {
                        value: d.email,
                        label: d.email
                    }
                });
                newData.unshift(emptyUserVal);
                setUserData(newData);
            } else {
                setRegisterError(true);
                setErrorString("users couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => {
            setRegisterError(true);
            setErrorString("users couldn't be loaded, try refreshing again or contact administrator");
        });

        Api.get("Home/GetCustomers").then(response => {
            if (response.status == 200) {
                var newCustomerData = response.data.map((d: any) => {
                    return {
                        label: `${d.customerId24Seven} - ${d.name}`,
                        value: d.customerId24Seven
                    }
                });
                newCustomerData.unshift(emptyUserVal);
                setCustomerData(newCustomerData);
            }
        });

    }, []);

    const RegisterUser = (path: string) => {

        if (!office24SevenCustomerId || !office24SevenCustomerId.toString().length) {
            setRegisterError(true);
            setErrorString("please select customer first");
            return;
        }

        setLoading(true);
        const valuesToSubmit = { FirstName: firstName, LastName: lastName, CountryCode: countryCode, PhoneNumber: phoneNumber, Email: email, UserName: path == registerPath ? email : userName, sendMeEmail: sendMeEmail, Office24SevenCustomerId: office24SevenCustomerId };

        setRegisterError(false);

        Api.post(path, valuesToSubmit).then(response => {
            setLoading(false);
            if (response && response.status == 200) {
                setIsRegistered(GetUserStepCaption(UserStep.registerationSuccess));
                setTimeout(() => setIsRegistered(""), 7000);
            } else if (response && response.status == 201) {
                setIsRegistered(GetUserStepCaption(UserStep.updated));
                setTimeout(() => setIsRegistered(""), 7000);
                resetFormValues();
            } else if (response && response.status == 202) {
                setIsRegistered(GetUserStepCaption(UserStep.updatedWithoutEmailChange));
                setTimeout(() => setIsRegistered(""), 7000);
                resetFormValues();
            } else {
                SetupError(GetValidationError(ValidationErrorCodeType.invalidForm));
            }
        }, err => {
            setLoading(false);
            if (err.response.status == ErrorCodes.forbidden403 || err.response.status == ErrorCodes.preConditionFailed409 || err.response.status == ErrorCodes.serverError500) {
                SetupError(GetLoginError(err.response.status));
            } else {
                SetupError(GetValidationError(ValidationErrorCodeType.invalidForm));
            }
        });
    }

    const onCustomerSelected = (param: DknSelectValue) => {
        setOffice24SevenCustomerId(param.value);
    }

    const dknToggleProps: DknToggleProps = {
        radios: [{ name: 'Add', value: MappingMode.Add }, { name: 'Edit', value: MappingMode.Edit }],
        changeFun: (val: string): void => {

            setSelectedToggle(val);
            resetFormValues();
            setRegisterError(false);
            setIsRegistered("");
        }
    }

    const resetFormValues = () => {
        setFirstName("");
        setLastName("");
        setcountryCode("");
        setPhoneNumber("");
        setEmail("");
        setUserName("");
        setOffice24SevenCustomerId(null);
        setSendMeEmail(false);
        setDefaultSelectedVal(emptyUserVal);
        setDefaultSelectedUserVal(emptyUserVal);
    }

    const fillFormValues = (param: any) => {
        setFirstName(param.firstName);
        setLastName(param.lastName);
        setcountryCode(param.countryCode);
        setPhoneNumber(param.phoneNumber);
        setEmail(param.email);
        setUserName(param.userName);
        setSendMeEmail(false);
        if (customerData && customerData.length) {
            var valArr = customerData.filter((d: any) => d.value == param.office24SevenCustomerId);
            if (valArr.length) {
                setDefaultSelectedVal(valArr[0]);
                setOffice24SevenCustomerId(valArr[0].value);
            }
        }
    }

    const deleteUser = () => {
        setLoading(true);
        var postValues = { Email: email, SendMeEmail: false };
        Api.post(deletePath, postValues).then(response => {
            setLoading(false);
            if (response.status == 200) {
                setIsRegistered("User deleted!");
                var filtered = userData.filter((userdata: any) => {
                    return userdata.value !== email;
                });
                setUserData(filtered);
                resetFormValues();
            } else {
                SetupError("couldn't remove the user, An error occurred");
            }
        }, err => {
            setLoading(false);
            SetupError(err.error.description);
        });
    }

    const cancelFunc = () => { }

    return (

        <div className="register">

            <DknHeading title="Register local user" description="Manage local users, and their data including adding & deleting." />

            <div className="action-buttons">
                <div className="toggle-button">
                    <DknToggle {...dknToggleProps} />
                </div>
            </div>

            <hr />

            {registerError && <div className="register-error">{errorString}</div>}

            {selectedToggle == MappingMode.Edit && <>
                <DknSelect data={userData} onSelected={onUserSelected} label="Select user" selectedValue={defaultSelectedUserVal} />
            </>}

            <div className="form-group">
                <DknSelect data={customerData} onSelected={onCustomerSelected} label="Select customer" selectedValue={defaultSelectedVal} />
            </div>

            <DknInput dknId="firstName" dknLabel="First name" dknPlaceholder="Example: Fornavn Mellomnavn" changeFunc={(val: any) => setFirstName(val)} dknType="text" value={firstName} />

            <DknInput dknId="lastName" dknLabel="Last name" dknPlaceholder="Example: Last name" changeFunc={(val: any) => setLastName(val)} dknType="text" value={lastName} />

            <DknInput dknId="countryCode" dknLabel="Country code" dknPlaceholder="Example: +47" changeFunc={(val: any) => setcountryCode(val)} dknType="text" value={countryCode} />

            <DknInput dknId="phoneNumber" dknLabel="Mobile number" dknPlaceholder="Example: +47" changeFunc={(val: any) => setPhoneNumber(val)} dknType="text" value={phoneNumber} />

            <DknInput dknId="email" dknLabel="Email" dknPlaceholder="Example: eksempel.bruker@domene.no" changeFunc={(val: any) => setEmail(val)} dknType="text" value={email} />

            <div className="checkbox">
                <label htmlFor="sendMeEmail">
                    <input type="checkbox" value="" onChange={event => setSendMeEmail(event.target.checked)} id="sendMeEmail" /> Send the same link as user gets to myself</label>
            </div>

            <div className="form-group" style={{ display: "flex" }}>
                {selectedToggle == MappingMode.Edit ?
                    <div style={{ marginTop: "16px", marginRight: "9px" }}><DknBtn type="button" clickFunc={() => RegisterUser(editPath)} title="Save changes" disabled={loading} loading={loading} /></div>
                    :
                    <div style={{ marginTop: "16px" }}><DknBtn type="button" clickFunc={() => RegisterUser(registerPath)} title="Register user" disabled={loading} loading={loading} /></div>
                }
                {selectedToggle == MappingMode.Edit && email.length > 0 &&
                    <div style={{ marginTop: "16px" }}><DknBtn type="button" isDelete={true} clickFunc={() => childRef && childRef.current && childRef.current.handleShowModal()} title="Delete user" disabled={loading} loading={loading} /></div>}
            </div>
            {isRegistered.length > 0 && <h6 className="registration-success">{isRegistered}</h6>}

            <DknModal ref={childRef} description="Are you sure?" title="Delete user" confirmText="proceed" cancelText="Cancel" confirmFunc={deleteUser} cancelFunc={cancelFunc} />
        </div>
    )
};

export default Register;