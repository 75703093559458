import Config from './Config';
import axios from 'axios';
import NavigationService from './NavigationService';
import { loadState } from "./LocalStorage";

const headers = () => {
    const currentState = loadState();
    return {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${currentState?.token}`,
        },
    };
};

const handleErrors = (handleError: any, error: any, path: any) => {
    if (
        error.response &&
        error.response.status &&
        error.response.status >= 400
    ) {
        if (error.response.status === 401) {
            //NavigationService.replace('/login');
            throw error;
            //return error;
        }
    }
    throw error;
    return error;
};

export const get = async (path: string, handleError = true) => {
    try {
        return await axios.get(Config.apiUrl + path, {
            headers: headers().headers
        });
    } catch (error) {
        throw handleErrors(handleError, error, path);
    }
};

type withDataTypes = 'put' | 'post';
const withData = async (
    path: string,
    data?: object,
    method: withDataTypes = 'post',
    handleError: boolean = true
) => {
    const body = data ? JSON.stringify(data) : undefined;
    try {
        console.log(Config.apiUrl);
        return await axios[method](Config.apiUrl + path, body, {
            headers: headers().headers
        });
    } catch (error) {
        throw handleErrors(handleError, error, path);
    }
};

export const post = async (path: string, data?: object, handleError = true) => {

    return await withData(path, data, 'post', handleError);
};

export const put = async (path: string, data?: object, handleError = true) => {
    return await withData(path, data, 'put', handleError);
};
