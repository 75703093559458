import * as React from 'react';
import { useState } from 'react';
import './SmartstoreMappings.scss';
import * as Api from '../../shared/Api';
import { FrontEndPoints, SmartStoreRegistrationCodes } from '../../shared/Common';
import history from '../../shared/history';
import Select from 'react-select';
import mappingsData from './mappingsData.json';
import { OverlayTrigger, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip } from 'react-bootstrap';
import { DknToggle, DknToggleProps } from '../../dkn-toggle/DknToggle';
import { DknSelect, DknSelectValue } from '../../dkn-select/DknSelect';
import JasperMapping from '../../jasper-mapping/JasperMapping';
import Office365Mapping from '../../office365-mapping/Office365Mapping';
import DknHeading from '../../dkn-heading/DknHeading';
import DknInput from '../../dkn-input/DknInput';
import DknBtn from '../../dkn-btn/DknBtn';


export enum MappingMode {
    Add = '1',
    Edit = '2'
}

export enum ApplicationType {
    Smartstore,
    Jasper,
    MinButtik,
    All
}

const SmartstoreMappings = (props: any) => {

    const initialValues = { applicationName: "", userName: "", user: "", userPassword: "", companyId: "", companyPassword: "", storeId: "", isJasper: false, jasperName: "" };

    const [formVals, setFormVals] = useState(initialValues);

    const [registerError, setRegisterError] = useState("");
    const [registeSuccess, setRegisteSuccess] = useState("");
    const [applications, setApplications] = useState() as any;
    const [results, setResults] = useState([]) as any;
    const [loading, setLoading] = useState(false);
    const [mappingsFound, setMappingsFound] = useState(false);
    const [selectedToggle, setSelectedToggle] = useState(MappingMode.Add) as any;
    const [smartStoreUsers, setSmartStoreUsers] = useState() as any;
    const [selectedSmartStoreUser, setSelectedSmartStoreUser] = useState("");
    const [jasperData, setJasperData] = useState({} as Array<DknSelectValue>);

    React.useEffect(() => {
        history.push(FrontEndPoints.smartStore);

        Api.post("home/getApplicationNames", { appType: ApplicationType.Smartstore }).then(res => {
            if (res.status === 200) {
                setApplications(res.data.map((d: any) => {
                    return {
                        value: d,
                        label: d
                    }
                }));
            } else {
                setRegisterError("Application couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => setRegisterError("Application couldn't be loaded, try refreshing again or contact administrator"));

        Api.post("home/getApplicationNames", { appType: ApplicationType.Jasper }).then(res => {
            if (res.status === 200) {
                var data = res.data.map((d: any) => {
                    return {
                        value: d,
                        label: d
                    } as DknSelectValue
                }) as Array<DknSelectValue>;
                setJasperData(data);
            } else {
                setRegisterError("Application couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => setRegisterError("Application couldn't be loaded, try refreshing again or contact administrator"));

        Api.get("Home/GetSmartstoreUsers").then(res => {
            if (res.status === 200) {
                setSmartStoreUsers(res.data.map((d: any) => {
                    return {
                        value: d.value,
                        label: d.label
                    }
                }));
            } else {
                setRegisterError("Application couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => setRegisterError("Application couldn't be loaded, try refreshing again or contact administrator"));        

    }, []);

    const validateSSForm = () => {
        setRegisteSuccess("");
        setRegisterError("");
        let errorString = "";

        if (!formVals.applicationName.length) {
            errorString = "Select application name first.";
        } else if (!formVals.userName.length) {
            errorString = "User name cannot be empty.";
        } else if (!formVals.user.length) {
            errorString = "User cannot be empty.";
        }
        if (errorString.length) {
            setRegisterError(errorString);
            return false;
        }
        return true;
    }

    const registerStore = () => {

        if (!validateSSForm()) {
            return;
        }

        setLoading(true);
        Api.post("home/AddSmartStore", formVals).then(response => {
            setLoading(false);
            setRegisterError("");
            setRegisteSuccess("User application registered!")
            console.log(response);
        }, err => {
            setLoading(false);
            setRegisteSuccess("");
                if (err.response.status == SmartStoreRegistrationCodes.userNotFound) {
                    setRegisterError("User not found");
                } else if (err.response.status == SmartStoreRegistrationCodes.userAlreadyExists) {
                    setRegisterError("User already exists");
                } else if (err.response.status == SmartStoreRegistrationCodes.badRequest) {
                    setRegisterError("Domain is not correct");
                }else if (err.response.status == SmartStoreRegistrationCodes.appMappingFound) {
                    setRegisterError("One mapping already exists for this user");
                } else {
                setRegisterError("Server error");
            }
            console.log(err);
        });
    }

    const updateStore = () => {
        setLoading(true);

        var postData: any = { ...formVals };
        postData.ssoUserName = selectedSmartStoreUser;

        Api.post("Home/UpdateSmartstore", postData).then(response => {
            if (response.status == 200) {
                setLoading(false);
                setRegisterError("");
                setRegisteSuccess("SmartStore mapping updated!");
                setFormVals(initialValues);
                setSelectedSmartStoreUser("");
                setMappingsFound(false);
            } else {
                setLoading(false);
                setRegisteSuccess("");
                setRegisterError("Error in updating smartstore mapping");
            }
        }, err => {
            setLoading(false);
            setRegisteSuccess("");
            setRegisterError("Error in updating smartstore mapping");
        });
    }

    const handleApplicationChange = (param: any) => {
        if (selectedToggle == MappingMode.Edit) {
            LoadSelectedSmartstore(param.value);
        } else {
            setFormVals({ ...formVals, applicationName: param.value });
        }
    }

    const syncMappings = () => {
        setLoading(true);
        var data = {
            smartstoreList: mappingsData
        };

        Api.post("home/syncMappings", data).then(response => {
            setLoading(false);
            if (response.status == 200) {
                if (response.data.length) {
                    setResults(response.data);
                    setRegisteSuccess("Mappings synced from json file with Errors");
                } else {
                    setRegisteSuccess("Mappings synced from json file");
                }
            } else {
                setRegisteSuccess("No new mappins to synch");
            }
        }, err => {
            setLoading(false);
            console.log(err);
            setRegisterError("Error occured, try again");
        });
    }

    const dknToggleProps: DknToggleProps = {
        radios: [{ name: 'Add', value: MappingMode.Add }, { name: 'Edit', value: MappingMode.Edit }],
        changeFun: (val: string): void => {
            setSelectedToggle(val);
            val == MappingMode.Add && setSelectedSmartStoreUser("");

            setMappingsFound(false);
            setFormVals(initialValues);

            setRegisterError("");
            setRegisteSuccess("");
        }
    }

    const handlesmartStoreUserChange = (selectedVal: any) => {
        setSelectedSmartStoreUser(selectedVal.value);
        if (formVals.applicationName.length > 0) {
            LoadSelectedSmartstore(formVals.applicationName);
        }
    }

    const LoadSelectedSmartstore = (applicationName: string) => {
        Api.post("Home/LoadSelectedSmartStore", { Label: selectedSmartStoreUser, Value: applicationName }).then(response => {
            if (response.status == 200) {
                setMappingsFound(true);
                setLoading(false);
                setRegisterError("");
                setRegisteSuccess("");
                setFormVals({ applicationName: applicationName, userName: response.data.userName, user: response.data.user, userPassword: response.data.userPassword, companyId: response.data.companyId, companyPassword: response.data.companyPassword, storeId: response.data.storeId, isJasper: response.data.isJasper, jasperName: response.data.jasperName });
            } else {
                setFormVals(initialValues);
                setMappingsFound(false);
                setLoading(false);
                setRegisteSuccess("");
                setRegisterError("Error in loading smartstore mapping for selected user");
            }
        }, err => {
            setFormVals(initialValues);
            setMappingsFound(false);
            setLoading(false);
            setRegisteSuccess("");
            setRegisterError("Error in loading smartstore mapping for selected user");
        });
    }

    const onSelectedJasper = (selected: any) => {
        setFormVals({ ...formVals, jasperName: selected.value });
    }

    const tabsStyle: any = {
        "display": "flex",
        "flexWrap": "nowrap",
        "alignItems": "stretch",
        "margin": 0,
        "padding": 0,
        "marginTop": "6px"
    }


    return (
        <div className="ss-mappings">

            <Tabs defaultActiveKey="Smartstore" id="applicationMappings" style={tabsStyle}>
                <Tab eventKey="Smartstore" title="SmartStore" tabClassName="mapping-type" >

                    <DknHeading title="SmartStore mapping" description="Add or edit SmartStore mappings for an SSO user here. Be sure to provide correct information to avoid problems later." />

                    <div className="action-buttons">
                        <div className="toggle-button">
                            <DknToggle {...dknToggleProps} />
                        </div>
                        {/*<div className="sync-button">*/}
                        {/*    <button className="btn btn-dkn" disabled={loading} onClick={syncMappings}>*/}
                        {/*        {loading ? <span className="saving"><span>.</span><span>.</span><span>.</span></span> :*/}
                        {/*            "Sync from json"}*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>

                    {registerError.length > 0 && <div className="api-error-text center">{registerError}</div>}
                    {registeSuccess.length > 0 && <div>
                        <span className="api-success-text center">{registeSuccess}</span>
                        {results.length > 0 && <table className="table table-dark missing-info-table">
                            <thead>
                                <tr>
                                    <th scope="col">User</th>
                                    <th scope="col">Application</th>
                                    <th scope="col">Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map((d: any) => {
                                    return <tr>
                                        <td>{d.userName}</td>
                                        <td>{d.applicationName}</td>
                                        <td>{d.error}</td>
                                    </tr>
                                })}

                            </tbody>
                        </table>}
                    </div>}


                    {selectedToggle == MappingMode.Edit && <DknSelect data={smartStoreUsers} onSelected={handlesmartStoreUserChange} label="Select user to edit" />}

                    {(selectedToggle == MappingMode.Add || (selectedSmartStoreUser.length > 0 && selectedToggle == MappingMode.Edit)) &&
                        <DknSelect data={applications} onSelected={handleApplicationChange} label="SmartStore instance" />}

                    {(selectedToggle == MappingMode.Add || mappingsFound) && <>

                        {selectedToggle == MappingMode.Add && <DknInput dknId="userName" dknLabel="Username" dknPlaceholder="Example: user@domain.com" changeFunc={val => setFormVals({ ...formVals, userName: val })} dknType="text" value={formVals.userName} />}
                        
                        <DknInput dknId="user" dknLabel="SmartStore user (CS10)" dknPlaceholder="Example: WTEST" changeFunc={val => setFormVals({ ...formVals, user: val })} dknType="text" value={formVals.user} />
                        
                        <DknInput dknId="userPassword" dknLabel="Password (CS10)" dknPlaceholder="Example: 1234" changeFunc={val => setFormVals({ ...formVals, userPassword: val })} dknType="text" value={formVals.userPassword} />

                        <DknInput dknId="company" dknLabel="Company (CS00)" dknPlaceholder="Example: 10001" changeFunc={val => setFormVals({ ...formVals, companyId: val })} dknType="text" value={formVals.companyId} />

                        <DknInput dknId="companyPassword" dknLabel="Company password (CS00)" dknPlaceholder="Example: 4321" changeFunc={val => setFormVals({ ...formVals, companyPassword: val })} dknType="text" value={formVals.companyPassword} />
                 

                        <DknInput dknId="storeId" dknLabel="Store id/number (BD88)" dknPlaceholder="Example: 001" changeFunc={val => setFormVals({ ...formVals, storeId: val })} dknType="text" value={formVals.storeId} />

                        <div className="checkbox remember-me">
                            <label htmlFor="jasperMapping">
                                <input type="checkbox" value="" checked={formVals.isJasper} onChange={event => setFormVals({ ...formVals, isJasper: event.target.checked })} id="jasperMapping" />&nbsp; Add Jasper mapping</label>
                            <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                        Enable "Add Jasper mapping" if you want to enable Jasper reports (SmartStore reporting) for this user based on the user credentials from SmartStore provided above. You can explicitely add it from Reporting link as well.
                                </Tooltip>
                                }
                            >
                                <img src="../question-mark.svg" alt="?" className="dekon-icon question-icon" />
                            </OverlayTrigger>
                        </div>

                        {formVals.isJasper && < DknSelect data={jasperData} onSelected={onSelectedJasper} label="Select Jasper server" />}

                        {selectedToggle == MappingMode.Add ?
                            <div style={{ marginTop: "16px" }}><DknBtn loading={loading} disabled={loading} title="Register" type="button" clickFunc={() => registerStore()} /></div> :
                        <div style={{ marginTop: "16px" }}><DknBtn loading={loading} disabled={loading} title="Update" type="button" clickFunc={() => updateStore()} /></div>}
                    </>}
                </Tab>
                <Tab eventKey="Jasper" title="Reporting" tabClassName="mapping-type">
                    <JasperMapping />
                </Tab>
                <Tab eventKey="Office365" title="Office 365" tabClassName="mapping-type">
                    <Office365Mapping />
                </Tab>
            </Tabs>
        </div>
    );
}

export default SmartstoreMappings;