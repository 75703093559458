import * as React from 'react';
import './Home.scss';
import * as Api from '../shared/Api';
import { useAuthState } from '../context/Context';
import { loadState, LocalData } from '../shared/LocalStorage';
import { ServiceAvailability } from '../shared/Common';
import Select from 'react-select';
import { ApplicationType } from '../smartstore/mappings/SmartstoreMappings';
import DknHeading from '../dkn-heading/DknHeading';
import { DknSelect } from '../dkn-select/DknSelect';
import DknModal from '../dkn-modal/DknModal';
import { useRef } from 'react';
import jwt_decode from "jwt-decode";

export const Home = (props: any) => {

    const [hasApplicationClaim, setHasApplicationClaim] = React.useState(ServiceAvailability.undefined);
    const [hasJasper, setHasJasper] = React.useState(ServiceAvailability.undefined);
    const [applications, setApplications] = React.useState([]) as any;
    const [jasperApps, setJasperApps] = React.useState([]) as any;
    const [selectedApp, setSelectedApp] = React.useState("");
    const [normalizedSmartstore, setNormalizedSmartstore] = React.useState("");
    const [selectedJasperApp, setSelectedJasperApp] = React.useState("");
    const [selectionError, setSelectionError] = React.useState("");
    const [jasperSelectionError, setJasperSelectionError] = React.useState("");
    const [smartstoreHref, setSmartstoreHref] = React.useState("");
    const [office365MailHref, setOffice365MailHref] = React.useState("");
    const [office365WordHref, setOffice365WordHref] = React.useState("");
    const [office365ExcelHref, setOffice365ExcelHref] = React.useState("");
    const [office365PowerpointHref, setOffice365PowerpointHref] = React.useState("");
    const [office365OnenoteHref, setOffice365OnenoteHref] = React.useState("");
    const [isOffice365, setIsOffice365] = React.useState(false);
    const [loggedInUser, setLoggedInUser] = React.useState("");

    const servicePopupRef: any = useRef();
    const localData = loadState();

    const userDetails: LocalData = useAuthState() as LocalData;
    let instanceCount = 1;

    React.useEffect(() => {

        if (userDetails && userDetails.token && userDetails.token.length) {
            var decoded: any = jwt_decode(userDetails.token);
            if (decoded && decoded.email) {
                setLoggedInUser(decoded.email);
            }
        }

        getApplicationNames(ApplicationType.Smartstore);
        getApplicationNames(ApplicationType.Jasper);

        if (!isAdUser()) {
            setSelectedApp("smartstore");
            setNormalizedSmartstore("smartstore");
            setSelectedJasperApp("jasper");
        }

        Api.post("home/ApplicationClaim", { AppType: ApplicationType.All }).then(response => {
            if (response.status === 200 && response.data === true) {
                setHasApplicationClaim(ServiceAvailability.available);
            }
        });

        Api.post("home/ApplicationClaim", { AppType: ApplicationType.Jasper }).then(response => {
            if (response.status === 200 && response.data === true) {
                setHasJasper(ServiceAvailability.available);
            } else {
                setHasJasper(ServiceAvailability.notFound);
            }
        });

        Api.get("office365/isOffice365User").then(result => {

            if (result) {
                setIsOffice365(result.data);
            }
        });

        if (localData) {
            var ssHref = "/Home/GetSmartStore?selectedApp=" + selectedApp.replace(/\s+/g, '%20') + "&token=" + localData.token;
            setSmartstoreHref(ssHref);
            var commonHref = "/office365/graphRedirect" + "?token=" + localData.token;

            var mailHref = commonHref + "&state=mail";
            setOffice365MailHref(mailHref);

            var wordHref = commonHref + "&state=word";
            setOffice365WordHref(wordHref);
            var excelHref = commonHref + "&state=excel";
            setOffice365ExcelHref(excelHref);
            var powerpointHref = commonHref + "&state=powerpoint";
            setOffice365PowerpointHref(powerpointHref);
            var onenoteHref = commonHref + "&state=onenote";
            setOffice365OnenoteHref(onenoteHref);
        }


    }, []);

    const getApplicationNames = (type: ApplicationType) => {
        Api.post("home/getUserApplicationNames", { appType: type }).then(res => {
            if (res.status === 200) {
                var refinedData = res.data.map((d: any) => {
                    return { value: d, label: d }
                });
                if (type == ApplicationType.Smartstore) {
                    setApplications(refinedData);
                } else {
                    setJasperApps(refinedData);
                }
            }
        }, err => console.log(err));
    }

    const goToSmartStore = (e: any) => {
        e.preventDefault();
        if (e.ctrlKey) {
            var name = selectedApp.replace(/\s+/g, '');

            if (typeof launchApplication.winrefs != 'undefined' && typeof launchApplication.winrefs[name] != 'undefined') {
                instanceCount += 1;
                name = selectedApp + instanceCount;
                while (typeof launchApplication.winrefs[name] != 'undefined') {
                    instanceCount += 1;
                    name = selectedApp + instanceCount;
                }
            }

            launchApplication('', name, ApplicationType.Smartstore);
            instanceCount += 1;
        } else {
            launchApplication('', selectedApp.replace(/\s+/g, ''), ApplicationType.Smartstore);
        }
    }

    const goToJasper = function (this: any, e: any) {
        e.preventDefault();
        if (e.ctrlKey) {
            var name = selectedJasperApp.replace(/\s+/g, '');

            if (typeof launchApplication.winrefs != 'undefined' && typeof launchApplication.winrefs[name] != 'undefined') {
                instanceCount += 1;
                name = selectedJasperApp + instanceCount;
                while (typeof launchApplication.winrefs[name] != 'undefined') {
                    instanceCount += 1;
                    name = selectedJasperApp + instanceCount;
                }
            }

            launchApplication('', name, ApplicationType.Jasper);
            instanceCount += 1;
        } else {
            launchApplication('', selectedJasperApp.replace(/\s+/g, ''), ApplicationType.Jasper);
        }
    }

    const goToMinbutikk = (e: any) => {
        e.preventDefault();
        launchApplication('', selectedApp, ApplicationType.MinButtik);
    }

    const launchApplication: any = function (l_url: any, l_windowName: any, appType: number = ApplicationType.All) {

        if (appType !== ApplicationType.Jasper && !selectedApp.length) {
            setSelectionError("Please select smartstore installation first");
            return;
        }

        if (appType === ApplicationType.Jasper && !selectedJasperApp.length) {
            setJasperSelectionError("Please select Jasper installation first");
            return;
        }

        if (typeof launchApplication.winrefs == 'undefined') {
            launchApplication.winrefs = {};
        }

        if (appType == ApplicationType.MinButtik) {
            l_windowName = l_windowName + "dknMinbutikk";
        }

        if (typeof launchApplication.winrefs[l_windowName] == 'undefined' || launchApplication.winrefs[l_windowName].closed) {
            let dknForm: any;
            let dekonJasperRedirectForm: any;

            if (appType == ApplicationType.Smartstore) {
                dknForm = document.getElementById('dekonForm');
                dknForm.action = "Home/LoadSmartStore";
            } else if (appType == ApplicationType.MinButtik) {
                dknForm = document.getElementById('dekonForm');
                dknForm.action = "Home/LoadMinbutikk";
            } else if (appType == ApplicationType.Jasper) {
                dknForm = document.getElementById('dekonJasperForm');
                dekonJasperRedirectForm = document.getElementById('dekonJasperRedirectForm');
                dknForm.action = "Home/LoadJasper";
            }

            dknForm.target = l_windowName;

            launchApplication.winrefs[l_windowName] = window.open(l_url, l_windowName);
            dknForm.submit();
            if (dekonJasperRedirectForm) {
                dekonJasperRedirectForm.target = l_windowName;
                setTimeout(function () {
                    dekonJasperRedirectForm.submit();
                }, 1000);
            }
        } else {
            launchApplication.winrefs[l_windowName].focus();
        }
    }

    const handleChange = (selectedStore: any) => {
        setSelectedApp(selectedStore.value);
        setNormalizedSmartstore(selectedStore.value.replace(/\s+/g, ''));
        setSelectionError("");
        setJasperSelectionError("");
        if (localData) {
            var ssHref = "/Home/GetSmartStore?selectedApp=" + selectedStore.value.replace(/\s+/g, '%20') + "&token=" + localData.token;

            setSmartstoreHref(ssHref);
        }
    }

    const detectMobile = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const handleJasperChange = (selectedStore: any) => {
        setSelectedJasperApp(selectedStore.value);
        setSelectionError("");
        setJasperSelectionError("");
    }

    const isAdUser = (): boolean => {
        return userDetails.roles.filter(d => d != "User").length > 0;
    }

    const linkparentClick = (evt: any) => {

        if (!selectedApp.length) {
            evt.preventDefault();
            evt.stopPropagation();
            setSelectionError("Please select smartstore installation first");
            return;
        }

        var localWindowName = selectedApp.replace(/\s+/g, '');

        if (typeof launchApplication.winrefs == 'undefined') {
            launchApplication.winrefs = {};
        }

        if (evt.ctrlKey || evt.metaKey || detectMobile() || typeof launchApplication.winrefs[localWindowName] == 'undefined' || launchApplication.winrefs[localWindowName].closed) {
            setTimeout(() => {
                launchApplication.winrefs[localWindowName] = window.open('', normalizedSmartstore);
            }, 200);

        } else {
            evt.preventDefault();
            evt.stopPropagation();
            launchApplication.winrefs[localWindowName] && launchApplication.winrefs[localWindowName].focus();
        }

    }

    const emptyIconClicked = () => {
        servicePopupRef && servicePopupRef.current && servicePopupRef.current.handleShowModal();
    }
    const cancelFunc = () => { }

    return (
        <div className="home">

            <div className="row">
                <div className="col-sm-12">
                    <DknHeading title="Dekon SSO Home" email={loggedInUser} description="Below you can find the links to services and features you have been assigned to." />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    {isAdUser() ? <>

                        <div className="smartstore-search">
                            <DknSelect data={applications} onSelected={handleChange} label="SmartStore & Min Butikk" />
                            {selectionError.length ? <label className="selection-error">{selectionError}</label> : ""}
                        </div>
                        <div className="dkn-links">
                            <div className="link-parent" onClick={linkparentClick}>
                                <a className="service-link smartstore" href={smartstoreHref} target={normalizedSmartstore} >
                                    <img src="ss-icon.webp" alt="Smart store" className="icon ss-icon" />
                                    <span className="hyper-link">Smartstore</span>
                                </a>
                            </div>

                            <a className="service-link min-butikk" onClick={goToMinbutikk} >
                                <img src="min-butikk_new.webp" alt="Min butikk" className="icon mb-icon" />
                                <span className="hyper-link">Min Butikk</span>
                            </a>
                        </div>

                        <div className="smartstore-search">
                            <DknSelect data={jasperApps} onSelected={handleJasperChange} label="Reporting" />
                            {jasperSelectionError.length ? <label className="selection-error">{jasperSelectionError}</label> : ""}
                        </div>
                        <div className="dkn-links">
                            <a className="service-link smartstore" onClick={goToJasper} >
                                <img src="jasper.webp" alt="Jasper" className="icon ss-icon" />
                                <span className="hyper-link">Reporting</span>
                            </a>
                        </div>
                    </> :
                        <>
                            {hasApplicationClaim == ServiceAvailability.available ?
                                <>
                                    <div className="dkn-links local">
                                        <div className="link-parent" onClick={linkparentClick}>
                                            <a className="service-link smartstore" href={smartstoreHref} target={normalizedSmartstore} >
                                                <img src="ss-icon.webp" alt="Smart store" className="icon ss-icon" />
                                                <span className="hyper-link">Smartstore</span>
                                            </a>
                                        </div>

                                        <a className="service-link min-butikk" onClick={goToMinbutikk} >
                                            <img src="min-butikk_new.webp" alt="Min butikk" className="icon mb-icon" />
                                            <span className="hyper-link">Min Butikk</span>
                                        </a>

                                        {hasJasper == ServiceAvailability.available &&
                                            <a className="service-link smartstore" onClick={goToJasper} >
                                                <img src="jasper.webp" alt="Jasper" className="icon ss-icon" />
                                                <span className="hyper-link">Reporting</span>
                                            </a>}
                                    </div>
                                </> :
                                <div className="">
                                    No Service is registered for this user. please ask your administrator to register services
                                </div>}
                        </>}

                    {isOffice365 ?
                        <div className="dkn-links office365">
                            <label className="office365-label">Office 365</label>
                            <div className="link-parent">
                                <a className="office365-link mail-link" href={office365MailHref} target="_blank" >
                                    <img src="Outlook.webp" alt="Outlook" className="office-icon" />
                                    <span className="hyper-link">MS Outlook</span>
                                </a>
                            </div>

                            <div className="link-parent">
                                <a className="office365-link mail-link" href={office365WordHref} target="_blank" >
                                    <img src="Word.webp" alt="Word" className="office-icon" />
                                    <span className="hyper-link">MS Word</span>
                                </a>
                            </div>
                            <div className="link-parent">
                                <a className="office365-link mail-link" href={office365ExcelHref} target="_blank" >
                                    <img src="Excel.webp" alt="Excel" className="office-icon" />
                                    <span className="hyper-link">MS Excel</span>
                                </a>
                            </div>
                            <div className="link-parent">
                                <a className="office365-link mail-link" href={office365PowerpointHref} target="_blank" >
                                    <img src="PowerPoint.webp" alt="PowerPoint" className="office-icon" />
                                    <span className="hyper-link">MS PowerPoint</span>
                                </a>
                            </div>
                            <div className="link-parent">
                                <a className="office365-link mail-link" href={office365OnenoteHref} target="_blank" >
                                    <img src="OneNote.webp" alt="OneNote" className="office-icon" />
                                    <span className="hyper-link">MS OneNote</span>
                                </a>
                            </div>
                        </div> :
                        <div className="dkn-links office365">
                            <label className="office365-label">Office 365</label>
                            <div className="link-parent">
                                <a className="office365-link mail-link disabled" onClick={emptyIconClicked} >
                                    <img src="Negative_Outlook.webp" alt="Outlook" className="office-icon" />
                                    <span className="hyper-link disabled">MS Outlook</span>
                                </a>
                            </div>

                            <div className="link-parent">
                                <a className="office365-link mail-link disabled" onClick={emptyIconClicked} >
                                    <img src="Negative_Word.webp" alt="Outlook" className="office-icon" />
                                    <span className="hyper-link disabled">MS Word</span>
                                </a>
                            </div>
                            <div className="link-parent">
                                <a className="office365-link mail-link disabled" onClick={emptyIconClicked} >
                                    <img src="Negative_Excel.webp" alt="Outlook" className="office-icon" />
                                    <span className="hyper-link disabled">MS Excel</span>
                                </a>
                            </div>
                            <div className="link-parent">
                                <a className="office365-link mail-link disabled" onClick={emptyIconClicked} >
                                    <img src="Negative_PowerPoint.webp" alt="Outlook" className="office-icon" />
                                    <span className="hyper-link disabled">MS PowerPoint</span>
                                </a>
                            </div>
                            <div className="link-parent">
                                <a className="office365-link mail-link disabled" onClick={emptyIconClicked} >
                                    <img src="Negative_OneNote.webp" alt="Outlook" className="office-icon" />
                                    <span className="hyper-link disabled">MS OneNote</span>
                                </a>
                            </div>
                        </div>
                    }

                    <DknModal ref={servicePopupRef} cancelText="Close" cancelFunc={cancelFunc} description="You are not subscribed to Office 365 services. Please contact us to find out more." title="Want Office 365?" centered={true} />
                </div>

            </div>

            <form id="dekonForm" method="post" action="Home/LoadSmartStore" target={selectedApp}>
                <input type="hidden" name="jwtToken" value={userDetails.token} />
                {isAdUser() && <input type="hidden" name="selectedApp" value={selectedApp} />}
            </form>

            <form id="dekonJasperForm" method="post" action="Home/LoadJasper" target={selectedJasperApp}>
                <input type="hidden" name="jwtToken" value={userDetails.token} />
                {isAdUser() && <input type="hidden" name="selectedApp" value={selectedJasperApp} />}
            </form>

            <form id="dekonJasperRedirectForm" method="post" action="Home/RedirectJasper" target={selectedJasperApp}>
                <input type="hidden" name="jwtToken" value={userDetails.token} />
                {isAdUser() && <input type="hidden" name="selectedJasperApp" value={selectedJasperApp} />}
            </form>
        </div>
    );
}
