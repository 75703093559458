import {  useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavMenu from './NavMenu';
import React from 'react';
import { useAuthDispatch, useAuthState } from '../context/Context';
import { loadState } from './LocalStorage';
import { loginUser } from '../context/Actions';
import { useLocation, useNavigate } from 'react-router-dom';

export const Layout = (props: any) => {

    const userDetails: any = useAuthState();
    const dispatch: any = useAuthDispatch();
    const localData = loadState();
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        if (!userDetails.isSignedin && localData && localData.token && localData.token.length) {
            loginUser(dispatch, localData);
            navigate(location.pathname);
        }
    }, []);

        return (
            <div>
                <NavMenu />
                <Container>
                    <Row>
                        <Col sm={12}>
                            {props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    
}
