import * as React from 'react';
import { useState } from 'react';
import './ResendEmail.scss';
import * as Api from '../shared/Api';
import DknBtn from '../dkn-btn/DknBtn';
import DknInput from '../dkn-input/DknInput';
import DknHeading from '../dkn-heading/DknHeading';

const ResendEmail = (props: any) => {
    const [reConfirmation, setReConfirmation] = useState({ error: '', success: '', email: '' }) as any;
    const [resendMeEmail, setResendMeEmail] = useState(false);
    const [loading, setLoading] = useState(false);


    const resendEmail = () => {
        setReConfirmation({ ...reConfirmation, success: "" });
        setReConfirmation({ ...reConfirmation, error: "" });
        setLoading(true);

        Api.post("ActiveDirectory/ResendConfirmationEmail", { Email: reConfirmation.email, sendMeEmail: resendMeEmail }).then(response => {
            setLoading(false);
            if (response.status == 200) {
                setReConfirmation({ ...reConfirmation, success: "Confirmation email sent again" });
            } else if (response.status == 409) {
                setReConfirmation({ ...reConfirmation, success: "Email is already confirmed" });
            } else {
                setReConfirmation({ ...reConfirmation, success: "There was an error. Email couldn't be sent" });
            }
        }, err => {
                setLoading(false);
            setReConfirmation({ ...reConfirmation, success: "There was an error. Email couldn't be sent" });
        });
    };

    return (
        <div className="resend-email">

            <DknHeading title="Resend confirmation email" description="Please fill in the correct email to get another email with containing link." />
            <DknInput dknId="resendEmail" dknLabel="Email" dknPlaceholder="eksempel.bruker@domene.no" changeFunc={val => setReConfirmation({ ...reConfirmation, email: val })} dknType="text" value={reConfirmation.email} />

            <div className="checkbox">
                <label htmlFor="reSendMeEmail">
                    <input type="checkbox" value="" onChange={event => setResendMeEmail(event.target.checked)} id="reSendMeEmail" /> Send me link in email</label>
            </div>            
            <div style={{ marginTop: "16px" }}>
                <DknBtn loading={loading} disabled={loading} title="Resend email" type="button" clickFunc={() => resendEmail()} />
            </div>
            {reConfirmation.success && reConfirmation.success.length > 0 && <h6 className="api-success-text center">{reConfirmation.success}</h6>}
            {reConfirmation.error && reConfirmation.error.length > 0 && <h6 className="api-error-text center">{reConfirmation.error}</h6>}
        </div>
    )
}

export default ResendEmail;