import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Layout } from './components/shared/Layout';
import { Router, Route, Navigate, Routes, useLocation, BrowserRouter, unstable_HistoryRouter as HistoryRouter, useNavigate } from "react-router-dom";
import PrivateRoute from './components/shared/PrivateRoute';
import AdminRoute from './components/shared/AdminRoute';
import TemporaryRoute from './components/shared/TemporaryRoute';
import { Login } from './components/login/Login';
import Logout from './components/logout/Logout';
import SmartstoreMappings from './components/smartstore/mappings/SmartstoreMappings';
import { AdLogin } from './components/activeDirectory/adLogin/AdLogin';
import { Home } from './components/home/Home';
import history from './components/shared/history';
import Register from './components/register/Register';
import RegisterUser from './components/register-user/RegisterUser';
import { loadState, removeState } from './components/shared/LocalStorage';
import { AuthProvider, useAuthDispatch, useAuthState } from './components/context/Context';
import { FrontEndPoints, MapPath } from './components/shared/Common';
import ConfirmEmail from './components/confirmEmail/ConfirmEmail';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/ResetPassword';
import { Application } from './components/application/Application';
import { UpdateRole } from './components/updateRole/UpdateRole';
import { AuthOptions } from './components/authOptions/AuthOptions';
import DknUsers from './components/dkn-users/DknUsers';
import { loginUser } from './components/context/Actions';
import ApiToken from './components/api-token/ApiToken';

function App(props: any) {

    const handleLogout = () => {
        removeState();
    }


    return (

        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path={FrontEndPoints.login} element={<Login />} />
                    <Route path={FrontEndPoints.activeDirectory} element={<AdLogin />} />
                    <Route path={FrontEndPoints.logout} element={<Logout />} />
                    <Route path={FrontEndPoints.confirmEmail} element={<ConfirmEmail />} />
                    <Route path={FrontEndPoints.forgotPassword} element={<ForgotPassword />} />
                    <Route path={FrontEndPoints.resetPassword} element={<ResetPassword />} />

                    <Route element={<PrivateRoute />}>
                        <Route path={FrontEndPoints.all} element={<Home />} />
                        <Route path={FrontEndPoints.updateRole} element={<UpdateRole />} />
                    </Route>
                    <Route element={<AdminRoute />}>
                        <Route path={FrontEndPoints.smartStore} element={<SmartstoreMappings />} />
                        <Route path={FrontEndPoints.register} element={<RegisterUser />} />
                        <Route path={FrontEndPoints.addApplication} element={<Application />} />
                        <Route path={FrontEndPoints.usersOverview} element={<DknUsers />} />
                        <Route path={FrontEndPoints.generateToken} element={<ApiToken />} />
                    </Route>

                    <Route element={<TemporaryRoute />}>
                        <Route path={FrontEndPoints.authOptions} element={<AuthOptions />} />
                    </Route>

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Layout>
        </BrowserRouter>

    );
}

export default App;
