import * as React from 'react';
import './DknUsers.scss';
import { Form, Table } from 'react-bootstrap';
import { useEffect } from 'react';
import * as Api from '../shared/Api';
import { DknSelect, DknSelectValue } from '../dkn-select/DknSelect';
import { CSVDownloader } from 'react-papaparse';
import { ToLocalDate } from '../shared/Common';
import { jsonToCSV } from 'react-papaparse'
import DknInput from '../dkn-input/DknInput';

enum DknUserConfirmationType {
    NotConfirmed = "0",
    Confirmed = "1",
    All = "2"
}

const DknUsers = (props: any) => {

    const [usersData, setUsersData] = React.useState() as any;
    const [error, setError] = React.useState("");
    const [date, setDate] = React.useState() as any;
    const [longinDate, setLoginDate] = React.useState() as any;
    const [emailOption, setEmailOption] = React.useState() as any;
    const [allData, setAllData] = React.useState() as any;
    const [emailData, setEmailData] = React.useState() as any;
    const [customersData, setCustomersData] = React.useState() as any;
    const [selectedEmail, setSelectedEmail] = React.useState("");
    const [selectedCustomer, setSelectedCustomer] = React.useState() as any;
    const [buffer, setBuffer] = React.useState("");
    const [showDownload, setShowDownload] = React.useState(false);
    const buttonRef: any = React.createRef();

    const [optionsData, setOptionsData] = React.useState([{ label: "All", value: DknUserConfirmationType.All }, { label: "Confirmed", value: DknUserConfirmationType.Confirmed }, { label: "Not confirmed", value: DknUserConfirmationType.NotConfirmed }] as Array<DknSelectValue>);

    let myTimer = 1001;
    let keys = "abcdefghijklmnopqrstuvwxyz ";

    useEffect(() => {

        const onKeyDown = (e: any) => {
            if (e.srcElement.nodeName != "INPUT" && keys.includes(e.key)) {

                e.preventDefault();

                if (myTimer > 3000) {
                    setBuffer(e.key);
                    myTimer = window.setInterval(resetBuffer, 3000);
                } else {
                    window.clearInterval(myTimer);
                    myTimer = window.setInterval(resetBuffer, 3000);
                    setBuffer(prevState => {
                        var next = prevState + e.key;
                        if (next == "i am an idiot") {
                            setShowDownload(true);
                        }
                        return next;
                    });
                }
            }
            //console.log(buffer);
        }


        Api.get("home/getLocalUsers").then(response => {
            if (response.status == 200) {
                //var data1 = response.data.map((d: any) => {
                //    return { email: d.email, phoneNumber: d.phoneNumber, createdAt: d.createdAt, emailConfirmed: d.emailConfirmed, name: d.name, lastLoginDate: d.lastLoginDate, customerName: d.customerName };
                //});



                var newObj = response.data.map((d: any) => {
                    return { email: d.email, phoneNumber: d.phoneNumber, createdAt: d.createdAt, emailConfirmed: d.emailConfirmed.toString(), name: d.name, lastLoginDate: d.lastLoginDate, customerName: d.customerName };
                });
                setAllData([...newObj]);
                setUsersData(newObj);

                var usersEmails = response.data.map((d: any) => {
                    return { label: d.email, value: d.email };
                });

                usersEmails.unshift({ label: "All", value: "" })

                setEmailData(usersEmails);

                var filteredCustomers = GetCustomers([...response.data])
                filteredCustomers.unshift({ label: "All", value: "" })

                setCustomersData(filteredCustomers);

            } else {
                setError("An error occurred, could not get users data!");
            }
        }, error => {
            setError("An error occurred, could not get users data!");
        });
        document.addEventListener('keydown', onKeyDown);


        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, []);

    const resetBuffer = () => {
        setBuffer("");
    }
    const downloadToCsv = (e: any) => {

        if (buttonRef.current) {
            var data = usersData.map((d: any) => {
                return {
                    Name: d.name,
                    Email: d.email,
                    Phone: d.phoneNumber,
                    LastLoginDate: d.lastLoginDate ? new Date(d.lastLoginDate) : null,
                    CustomerName: d.customerName,
                    createdAt: new Date(d.createdAt),
                    EmailConfirmed: d.emailConfirmed
                }
            });
            buttonRef.current.download(data);
        }
    }

    const createDateFilterChanged = (val: any) => {
        var createDateTime = val;
        setDate(createDateTime);
        applyFilter(emailOption, createDateTime, selectedEmail, selectedCustomer, longinDate);
    }

    const loginDateFilterChanged = (val: any) => {
        var loginDateTime = val;
        setLoginDate(loginDateTime);
        applyFilter(emailOption, date, selectedEmail, selectedCustomer, loginDateTime);
    }

    const onSelectedOption = (val: any) => {
        setEmailOption(val.value);
        applyFilter(val.value, date, selectedEmail, selectedCustomer, longinDate);
    }

    const onSelectedemail = (val: any) => {
        setSelectedEmail(val.value);
        applyFilter(emailOption, date, val.value, selectedCustomer, longinDate);
    }

    const onSelectedCustomer = (val: any) => {
        var customerArr: any = null;
        if (val && val.length) {
            customerArr = val.map((d: any) => d.value) as any;
        }
        setSelectedCustomer(customerArr);
        applyFilter(emailOption, date, selectedEmail, customerArr, longinDate);
    }

    const applyFilter = (option: DknUserConfirmationType, selectedCreatedate: number, emailparam: string, selectedCustomer: Array<string>, selectedLoginDateParam: number) => {
        var selectedData: any;
        var selectedDateTicks = selectedCreatedate && selectedCreatedate.toString().length ? new Date(selectedCreatedate).getTime() : new Date(2010, 1, 1).getTime();


        if (option == DknUserConfirmationType.Confirmed) {
            selectedData = allData.filter((d: any) => d.emailConfirmed == "true" && compareTime(d.createdAt, selectedDateTicks) && (!emailparam || emailparam.length == 0 || emailparam == d.email) && compareCustomer(d.customerName, selectedCustomer) && compareLoginTime(d.lastLoginDate, selectedLoginDateParam));
        } else if (option == DknUserConfirmationType.NotConfirmed) {
            selectedData = allData.filter((d: any) => d.emailConfirmed == "false" && compareTime(d.createdAt, selectedDateTicks) && (!emailparam || emailparam.length == 0 || emailparam == d.email) && compareCustomer(d.customerName, selectedCustomer) && compareLoginTime(d.lastLoginDate, selectedLoginDateParam));
        } else {
            selectedData = allData.filter((d: any) => compareTime(d.createdAt, selectedDateTicks) && (!emailparam || emailparam.length == 0 || emailparam == d.email) && compareCustomer(d.customerName, selectedCustomer) && compareLoginTime(d.lastLoginDate, selectedLoginDateParam));
        }

        setUsersData(selectedData);
    }

    const compareTime = (arrDate: number, selectedDate: number) => {
        if (!arrDate || !arrDate.toString().length) {
            return true;
        } else {
            return arrDate > selectedDate;
        }
    }

    const compareLoginTime = (arrDate: number, selectedDate: number) => {

        if (!selectedDate || !selectedDate.toString().length) {
            return true;
        } else if (!arrDate || !arrDate.toString().length) {
            return false;
        }

        var selectedLoginTicks = new Date(selectedDate).getTime();
        return arrDate > selectedLoginTicks;
    }

    const compareCustomer = (arrVal: string, selectedVal: Array<string>) => {
        if (!selectedVal || !selectedVal.length || selectedVal.indexOf(arrVal) != -1) {
            return true;
        } else if (selectedVal.indexOf("") != -1) {
            return true;
        }
        return false;
    }
    const GetCustomers = (arr: any) => {
        var filtered: Array<any> = [];
        for (var count = 0; count < arr.length - 1; count++) {
            var singleData = arr[count];
            if (singleData.customerName.length > 0) {
                var idx = filtered.filter((d: any) => d && d.label == singleData.customerName);
                if (idx.length == 0) {
                    filtered.push({ label: singleData.customerName, value: singleData.customerName });
                }
            }
        }
        return filtered;
    }

    return (

        <div className="dkn-users">
            <h3 className="page-heading"> Dekon SSO local users</h3>
            <div className="controls">
                <div className="users-filters">
                    <div className="filter">
                        <DknSelect data={emailData} onSelected={onSelectedemail} label="Email" />
                    </div>

                    <div className="filter">
                        <DknSelect data={optionsData} onSelected={onSelectedOption} label="Email Confirmed" />
                    </div>
                    <div className="filter">
                        <DknInput dknId="createdDate" dknLabel="Created after" dknPlaceholder="Created date" changeFunc={createDateFilterChanged} dknType="date" value={date} />
                    </div>
                </div>
                <div className="users-filters">
                    <div className="filter">
                        <DknSelect isMulti={true} data={customersData} onSelected={onSelectedCustomer} label="Customers" />
                    </div>
                    <div className="filter">
                        <DknInput dknId="loginDate" dknLabel="Login after" dknPlaceholder="Login date" changeFunc={loginDateFilterChanged} dknType="date" value={longinDate} />
                    </div>
                </div>
            </div>

            {showDownload && usersData && usersData.length > 0 && <div className="download-button filter">
                <CSVDownloader
                    ref={buttonRef}
                    data={usersData}
                    type="button"
                    filename={'filename'} className="btn btn-sm btn-hidden">
                    Download CSV file
                </CSVDownloader>
                <button className="btn btn-sm btn-block" onClick={downloadToCsv}>Download csv file</button>
            </div>}

            {error.length > 0 && < div className="users-error">
                {error}
            </div>}

            {usersData && usersData.length > 0 && <div className="user-data">
                <div className="table-responsive">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Last login</th>
                                <th>customer</th>
                                <th>Created date</th>
                                <th>Email confirmed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData.map((d: any, index: number) =>
                                <tr key={d.key}>
                                    <td>{index + 1}</td>
                                    <td className="dkn-row">{d.name}</td>
                                    <td className="dkn-row">{d.email}</td>
                                    <td className="dkn-row">{d.phoneNumber}</td>
                                    <td className="dkn-row">{ToLocalDate(d.lastLoginDate)}</td>
                                    <td className="dkn-row">{d.customerName}</td>
                                    <td className="dkn-row">{ToLocalDate(d.createdAt)}</td>
                                    <td className="dkn-row">{d.emailConfirmed.toString()}</td>
                                </tr>)}
                        </tbody>
                    </Table>
                </div>
                {buffer.length > 0 && <span className="vild-card">{buffer}</span>}
            </div>
            }
        </div>
    )
};

export default DknUsers;