import React from 'react';
import './DknHeading.scss';

export interface DknHeadingModel {
    title: string;
    description?: string;
    hideIcon?: boolean;
    email?: string;
}

const DknHeading = ({ title, description, hideIcon, email }: DknHeadingModel) => {
    return (
        <div className="dkn-heading">
            <div className="title">
                <h1>{title}</h1>
                {email && email.length > 0 && <span className="email"><span className="welcome">Welcome</span> {email}</span>}
                {!hideIcon && <img className="sso-logo" src="privateCloudIcon.svg" />}
            </div>
            <p className="description">{description}</p>
        </div>
    );
}

export default DknHeading;
