import * as React from 'react';
import { useState, useReducer } from 'react';
import { LocalData } from '../shared/LocalStorage';

export const initialState: LocalData = {	
	token: '',
	isSignedin: false,
	roles: []
};

export enum ActionTypes {
	LoginSuccess,
	LoadFromHook,
	Logout,
}

export const AuthReducer = (initialState: LocalData, action: any) => {
	switch (action.type) {
		case ActionTypes.LoadFromHook:
			return initialState;

		case ActionTypes.LoginSuccess:
		
			return {
				...initialState,
				isSignedin: action.payload.isSignedin,
				token: action.payload.token,
				roles: action.payload.roles,
			};
		case ActionTypes.Logout:
			return {
				...initialState,
				token: '',
				isSignedin: false,
				roles: []
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
