import { ActionTypes, initialState } from './Reducer';
import { removeState } from '../shared/LocalStorage';

export function loginUser(dispatch: any, loginPayload: any) {
    dispatch({ type: ActionTypes.LoginSuccess, payload: loginPayload });
}

export function logout(dispatch: any) {
    dispatch({ type: ActionTypes.Logout });
    removeState();
}
