import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import * as React from 'react';
import './NavMenu.scss';
import { useAuthState } from '../context/Context';
import { FrontEndPoints, ValidateIsAdmin, ValidateIsUser } from './Common';
import jwt_decode from "jwt-decode";
import { LocalData } from './LocalStorage';

const NavMenu = (props: any) => {

    const [loggedInUser, setLoggedInUser] = React.useState("");
    const userDetails: any = useAuthState() as LocalData;

    React.useEffect(() => {
        if (userDetails && userDetails.token && userDetails.token.length) {
            var decoded: any = jwt_decode(userDetails.token);
            if (decoded && decoded.email) {
                setLoggedInUser(decoded.email);
            }
        }
    }, [userDetails]);

    return (

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark dekon-nav">
            <Link className="navbar-brand" to={FrontEndPoints.all}><img src="logo.webp" alt="Dekon-IT" className="dekon-logo" /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#dekonNavLinks" aria-controls="dekonNavLinks" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="dekonNavLinks">
                <ul className="navbar-nav mr-auto">

                    {ValidateIsAdmin(userDetails) &&
                        <>
                        <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                            <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.generateToken}>Api-Token</NavLink>
                        </NavItem>
                        <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                            <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.usersOverview}>Users</NavLink>
                            </NavItem>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.updateRole}>Role</NavLink>
                            </NavItem>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.smartStore}>Mappings</NavLink>
                            </NavItem>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.addApplication}>Application</NavLink>
                            </NavItem>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.register}>Registration</NavLink>
                            </NavItem>
                        </>}
                    {ValidateIsUser(userDetails) ?
                        <>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged" to={FrontEndPoints.logout}>Logout</NavLink>
                            </NavItem>
                            {loggedInUser?.length && <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-green dkn-navlink-logged"><img src="user.svg" alt="user" className="user-icon" /></NavLink>
                            </NavItem>}
                            {/*userEmail?.length && <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-green dkn-navlink-logged"><div className="dkn-user"><img src="user.svg" alt="user" /></div><span>{userEmail}</span></NavLink>
                            </NavItem>*/}
                        </> :
                        <>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged dkn-navlink" to={FrontEndPoints.activeDirectory}>AD-login</NavLink>
                            </NavItem>
                            <NavItem data-toggle="collapse" data-target=".navbar-collapse.show">
                                <NavLink tag={Link} className="text-light dkn-navlink-logged dkn-navlink" to={FrontEndPoints.login}> Login</NavLink>
                            </NavItem>
                        </>}
                </ul>

            </div>
        </nav>
    );
}

export default NavMenu;