import * as React from 'react';
import './DknImport.scss';
import { CSVReader } from 'react-papaparse';
import { Table } from 'react-bootstrap';
import * as Api from '../shared/Api';
import { useState } from 'react';
import DknBtn from '../dkn-btn/DknBtn';
import DknHeading from '../dkn-heading/DknHeading';

export enum validationType {
    DknString,
    DknNumber,
    DknBool,
    DknEmail,
    DknAppName
}

const DknImport = (props: any) => {

    const formValues = { firstName: "", lastName: "", countryCode: "", phoneNumber: "", email: "", userName: "", sendMeEmail: false, applicationName: "", user: "", userPassword: "", companyId: "", companyPassword: "", storeId: "", isJasper: false, jasperName: "" };

    const [usersData, setUsersData] = useState() as any;
    const [syncResults, setSyncResults] = React.useState(null) as Array<any>;
    const [success, setSuccess] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [checkedState, setCheckedState] = useState(
        new Array(900).fill(true)
    );

    var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var specialCharsNoSpace = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
    var specialChars = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

    const handleOnDrop = (data: any) => {
        setSuccess("");
        setSyncResults(null);

        var mappedData = data.map((d: any, index: number) => {
            return {
                firstName: d.data["first name"],
                lastName: d.data["last name"],
                countryCode: d.data["country code"]?.trim(),
                phoneNumber: d.data["mobile number"]?.trim(),
                email: d.data["email"]?.trim(),
                office24SevenCustomerId: d.data["customerId"]?.trim(),
                userName: d.data["email"]?.trim(),
                sendMeEmail: false,
                applicationName: d.data["ssApplicationServer"]?.trim(),
                companyId: d.data["ssCompany"]?.trim(),
                companyPassword: d.data["ssCompanyPwd"]?.trim(),
                userPassword: d.data["ssPwd"]?.trim(),
                storeId: d.data["ssStoreId"]?.trim(),
                user: d.data["ssUser"]?.trim(),
                isJasper: ToBoolean(d.data["isJasper"]?.trim()),
                jasperName: d.data["jasperApplicationName"]?.trim(),
                mapToSso: true,
                mapToSsoName: "map" + index,
                key: index + 1
            }
        });
        setUsersData(mappedData);
    }

    const ToBoolean = (val: any) => {
        return (val && (val == 'TRUE' || val == 'true' || val == true || val == 1)) ? true : false;
    }

    const ValidateData = (val: any, type: validationType): boolean => {
        if (!val) { return false; }
        switch (type) {
            case validationType.DknNumber:
                return isNaN(val) == false && val.includes(".") == false ? true : false;
            case validationType.DknEmail:
                return val.match(emailPattern) == null ? false : true;
            case validationType.DknString:
                return !specialChars.test(val) && isNaN(val);
            case validationType.DknAppName:
                return !specialCharsNoSpace.test(val) && isNaN(val);
            case validationType.DknBool:
                return !val || val === 'true' || val === true || val === false || val === 'false';

            default:
                return false;
        }
    }

    const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
        console.log(err);
        setUsersData(null);
    }

    const handleOnRemoveFile = (data: any) => {
        setUsersData(null);
    }

    const config: any = {
        header: true
    };

    const submitCsvData = (): void => {
        setLoading(true);

        var dataToSend = usersData.filter((d: any, i: number) => checkedState[i] && d.email);

        Api.post("home/syncUsers", dataToSend).then(response => {

            setLoading(false);

            if (response.status == 200 && response.data && response.data.length) {
                setSyncResults(response.data);
            } else {
                setUsersData(null);
                setSuccess("data synced!");
            }
        }, error => {
            setSuccess("Error occurred at server!");
            setLoading(false);
        });
    }

    const handleOnChange = (position: any) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

    }

    const checkedValue = (index: number): boolean => {
        return usersData[index].mapToSso;
    }

    return (
        <div className="dkn-import">

            <div className="api-success-text center">{success}</div>

            <div className="upload">
                
                <DknHeading title="Dekon SSO Import." description="Import SSO local users and mappings from CSV file. Please use the specific file format mentioned in test channel in Teams" />

                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    noDrag
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                    config={config}
                >
                    <span>Click to upload.</span>
                </CSVReader>
            </div>
            {!syncResults && usersData && usersData.length > 0 && <div className="user-data">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>email</th>
                            <th>Customer id</th>
                            <th>country code</th>
                            <th>mobile number</th>
                            <th>ssApplicationServer</th>
                            <th>ssCompany</th>
                            <th>ssCompanyPwd</th>
                            <th>ssPwd</th>
                            <th>ssStoreId</th>
                            <th>ssUser</th>
                            <th>isJasper</th>
                            <th>jasperApplicationName</th>
                            <th>Map to SSO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersData.map((d: any, index: number) =>
                            <tr key={d.key}>
                                <td>{index + 1}</td>
                                <td className={ValidateData(d.firstName, validationType.DknString) ? "" : "invalid"}>{d.firstName}</td>
                                <td className={ValidateData(d.lastName, validationType.DknString) ? "" : "invalid"}>{d.lastName}</td>
                                <td className={ValidateData(d.email, validationType.DknEmail) ? "" : "invalid"}>{d.email}</td>
                                <td className={ValidateData(d.office24SevenCustomerId, validationType.DknNumber) ? "" : "invalid"}>{d.office24SevenCustomerId}</td>
                                <td className={ValidateData(d.countryCode, validationType.DknNumber) ? "" : "invalid"}>{d.countryCode}</td>
                                <td className={ValidateData(d.phoneNumber, validationType.DknNumber) ? "" : "invalid"}>{d.phoneNumber}</td>
                                <td className={ValidateData(d.applicationName, validationType.DknAppName) ? "" : "invalid"}>{d.applicationName}</td>
                                <td className={ValidateData(d.companyId, validationType.DknString) ? "" : "invalid"}>{d.companyId}</td>
                                <td className={ValidateData(d.companyPassword, validationType.DknString) ? "" : "invalid"}>{d.companyPassword}</td>
                                <td className={ValidateData(d.userPassword, validationType.DknString) ? "" : "invalid"}>{d.userPassword}</td>
                                <td className={ValidateData(d.storeId, validationType.DknNumber) ? "" : "invalid"}>{d.storeId}</td>
                                <td className={ValidateData(d.user, validationType.DknString) ? "" : "invalid"}>{d.user}</td>
                                <td className={ValidateData(d.isJasper, validationType.DknBool) ? "" : "invalid"}>{d.isJasper}</td>
                                <td className={ValidateData(d.jasperName, validationType.DknAppName) ? "" : "invalid"}>{d.jasperName}</td>
                                <td className="">
                                    <input
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name={d.mapToSsoName}
                                        value={d.mapToSsoName}
                                        checked={checkedState[index]}
                                        onChange={() => handleOnChange(index)}
                                    />
                                </td>
                            </tr>)}
                    </tbody>
                </Table>
            </div>}


            {syncResults && <table className="table table-dark missing-info-table">
                <thead>
                    <tr>
                        <th scope="col">User</th>
                        <th scope="col">Application</th>
                        <th scope="col">Error</th>
                    </tr>
                </thead>
                <tbody>
                    {syncResults.map((d: any) => {
                        return <tr>
                            <td>{d.userName}</td>
                            <td>{d.applicationName}</td>
                            <td>{d.error}</td>
                        </tr>
                    })}

                </tbody>
            </table>}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <DknBtn loading={loading} disabled={loading || !usersData || syncResults} title="Submit" type="button" clickFunc={submitCsvData} />
            </div>
        </div>
    )
}

export default DknImport;