import * as React from 'react';
import { useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './DknToggle.scss';

export interface DknToggleProps {
    radios: Array<DknRadio>;
    changeFun: (val: string) => void;
    disabled?: boolean,
    checkedValue?: any
}

export interface DknRadio {
    name: string;
    value: string;
}

export const DknToggle = ({ radios, changeFun, disabled, checkedValue }: DknToggleProps) => {

    const [radioValue, setRadioValue] = useState('1');
    const [btnDisabled, setBtnDisabled] = useState(false);

    const toggleChangeFunction = (val: string) => {
        console.log(val);
        setRadioValue(val);
        changeFun && changeFun(val);
    }

    useEffect(() => {
        radios && radios.length && setRadioValue(radios[0].value);
        setBtnDisabled(disabled as any);
        if (checkedValue && checkedValue.toString().length) {
            console.log(checkedValue, "setting button");
            setRadioValue(checkedValue);
        }

    }, [disabled, checkedValue]);

    const handlePlatformChange = (e: any) => {
        console.log(e.target.value);
    }

    const checkVal = () => {
        console.log("radioValue is: " + radioValue)
    }

    const onChange = (e: any) => {
        console.log(e.target.value);
    }

    const [value, setValue] = useState("battle");
    const handleChange = (val: any) => setValue("acti");

    return (
        <div className="dkn-toggle">
            {radios && radios.length > 0 &&
                <ToggleButtonGroup type="radio" id="buttongroup" name="buttongroup">
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            name="buttongroup"
                            disabled={btnDisabled}
                            key={radio.value}
                            id={radio.value}
                            variant="secondary"
                            value={radio.value}
                            checked={radioValue == radio.value}
                            onChange={(e: any) => toggleChangeFunction(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>}
        </div>
    );
}
