import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FrontEndPoints, GetChangePasswordFeedback } from '../shared/Common';
import * as Api from '../shared/Api';
import './ResetPassword.scss';
import history from "../shared/history";
import DknInput from '../dkn-input/DknInput';
import DknBtn from '../dkn-btn/DknBtn';
import DknHeading from '../dkn-heading/DknHeading';

const ResetPassword = (props: any) => {

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isPasswordChanged, setIsPasswordChanged] = useState("");
    const [isError, setIsError] = useState("");

    const changePassword = (): void => {

        if (!password.length || !repeatPassword.length || password !== repeatPassword) {
            setIsError("please set password correctly!");
            return;
        }

        setIsError("");

        var searchParams = new URLSearchParams(window.location.search);

        Api.post("login/ResetUserPassword", { password: password, repeatPassword: repeatPassword, Email: searchParams.get("email"), EmailToken: searchParams.get("token") }).then(response => {
            console.log(response);
            setIsError("");
            setIsPasswordChanged(GetChangePasswordFeedback(response.status));           
        }, err => {
                console.log(err);
                setIsPasswordChanged("");
                setIsError(GetChangePasswordFeedback(err.response.status));
        });
    }

    return (
        <div className="forgot-password">

            <DknHeading title="Reset password" description="set your new password." />

            {isError && <h6 className="password-error">{isError}</h6>}

            <DknInput dknId="password" dknLabel="Password" dknPlaceholder="password" changeFunc={val => setPassword(val)} dknType="password" value={password} />

            <DknInput dknId="repeatPassword" dknLabel="Confirm Password" dknPlaceholder="password" changeFunc={val => setRepeatPassword(val)} dknType="password" value={repeatPassword} />

            <div style={{ marginTop: "16px" }}><DknBtn title="Reset password" type="button" clickFunc={() => changePassword()} /></div>

            { isPasswordChanged.length > 0 &&
                <><h6 className="password-success">{isPasswordChanged}</h6>
                    <Link to={FrontEndPoints.login}>Go to Login page</Link>
                </>}            
        </div>
        );
}

export default ResetPassword;