import * as React from 'react';
import './Office365Mapping.scss';
import * as Api from '../shared/Api';
import { DknToggle, DknToggleProps } from '../dkn-toggle/DknToggle';
import { DknSelect, DknSelectValue } from '../dkn-select/DknSelect';
import { ApplicationType, MappingMode } from '../smartstore/mappings/SmartstoreMappings';
import DknHeading from '../dkn-heading/DknHeading';
import DknBtn from '../dkn-btn/DknBtn';
import DknInput from '../dkn-input/DknInput';

const Office365Mapping = (props: any) => {

    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [selectedToggle, setSelectedToggle] = React.useState(MappingMode.Add) as any;
    const [userEmail, setUserEmail] = React.useState("");
    const [userData, setUserData] = React.useState({} as Array<DknSelectValue>);
    const [loading, setLoading] = React.useState(false);
    const [toggleCheckedValue, setToggleCheckedValue] = React.useState('1');
    //const [statusPending, setStatusPending] = React.useState(true);

    const dknToggleProps: DknToggleProps = {
        radios: [{ name: 'Disable', value: '1' }, { name: 'Enable', value: '2' }],
        changeFun: (val: string): void => {
            setSelectedToggle(val);
            setError("");
            setSuccess("");            
        }
    }

    React.useEffect(() => {

        Api.get("home/getAllUsers").then(res => {
            if (res.status === 200) {
                setUserData(res.data.map((d: any) => {
                    return {
                        value: d.email,
                        label: d.email
                    }
                }));
            } else {
                setError("users couldn't be loaded, try refreshing again or contact administrator");
            }
        }, err => setError("users couldn't be loaded, try refreshing again or contact administrator"));

    }, []);

    const onUserSelected = (selectedUser: DknSelectValue) => {
        setUserEmail(selectedUser.value);
        setLoading(true);
        Api.post("office365/isOffice365User", { email: selectedUser.value, enableOffice365: false }).then(result => {
            //setStatusPending(false);
            if (result && result.data == false) {
                setToggleCheckedValue('1');
            } else if (result && result.data == true) {
                setToggleCheckedValue('2');
            }

            setError("");
            setSuccess("");
            setLoading(false);
        }, err => {
            setLoading(false);
            //setStatusPending(false);
            setError("Could not find the status of mapping for this user. please try again or contact administrator.");
            setSuccess("");

        });
    }

    const updateOffice365Mapping = (e: any) => {

        e.preventDefault();
        e.stopPropagation();

        setError("");
        setSuccess("");
        setLoading(true);

        var postData = { email: userEmail, enableOffice365: selectedToggle == '1' ? false : true  };

        Api.post("office365/enableOffice365", postData).then(response => {
            setLoading(false);
            if (response.status == 200) {
                setSuccess("Mapping added successfully");
                if (postData.enableOffice365) {
                    setToggleCheckedValue('2');
                } else {
                    setToggleCheckedValue('1');
                }
            } else {
                setError("mapping couldn't be added, try again or contact administrator");
            }
        }, err => {
            setLoading(false);
            if (err.response.status == 409) {
                setError("One mapping already exists for this user");
            } else {
                setError("mapping couldn't be added, try again or contact administrator");
            }
        });

    }

    return (
        <div className="office365-mapping">
            <DknHeading title="Reporting registration" description="Add the Jasper Reporting mappings for a user here." />

            <form onSubmit={updateOffice365Mapping} >
                <DknSelect data={userData} onSelected={onUserSelected} label="Username:" />

                <div className="action-buttons">
                    <div className="toggle-button">
                        <label className="toggle-button-label">User status:</label>
                        <DknToggle {...dknToggleProps} disabled={loading || userEmail.length == 0} checkedValue={toggleCheckedValue} />
                    </div>
                </div>

                <DknBtn loading={loading} disabled={loading || userEmail.length == 0} title="Update" type="submit" />
            </form>

            {success.length > 0 && <div className="api-success-text center">{success}</div>}
            {error.length > 0 && <div className="api-error-text center">{error}</div>}

        </div>
    );
}

export default Office365Mapping;