import * as React from 'react';
import './RegisterUser.scss';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';
import DknImport from '../dkn-import/DknImport';
import ResendEmail from '../resend-email/ResendEmail';
import Register from '../register/Register';
import * as Api from '../shared/Api';
import DknHeading from '../dkn-heading/DknHeading';
import DknBtn from '../dkn-btn/DknBtn';

const RegisterUser = (props: any) => {

    const [syncResponse, setSyncResponse] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const tabsStyle: any = {
        "display": "flex",
        "flexWrap": "nowrap",
        "alignItems": "stretch",
        "margin": 0,
        "padding": 0,
        "marginTop": "16px",
        "borderBottom": "0px",
        "overflow": "auto"
    }


    const syncOffice24SevenCustomers = (): void => {
        setLoading(true);
        Api.get("home/syncCustomers").then((response: any) => {
            setLoading(false);
            if (response.status == 200) {
                setSyncResponse("customers synced");
            } else {
                setSyncResponse("failed to sync");
            }
        }, error => {
            setLoading(false);
            setSyncResponse("Error occured, failed to sync customers from 24SevenOffice");
        });
    }


    return (

        <div className="register-user">
            <Tabs defaultActiveKey="register" id="registerTabs" style={tabsStyle} >
                <Tab eventKey="register" title="Register" tabClassName="dkn-tab">
                    <Register />
                </Tab>
                <Tab eventKey="import" title="Import" tabClassName="dkn-tab">
                    <DknImport />
                </Tab>
                <Tab eventKey="email" title="Resend Confirmation Email" tabClassName="dkn-tab">
                    <ResendEmail />
                </Tab>
                <Tab eventKey="office24Seven" title="24SevenOffice" tabClassName="dkn-tab">                    
                    <div className="sync-customers">
                        <DknHeading title="24SevenOffice" description="Sync customers from 24SevenOffice API to local Database. only 'Business' type customers with ID between 10000-15000 are kept." />

                        {syncResponse.length > 0 && <div className="sync-response">{syncResponse}</div>}
                        
                        <DknBtn loading={loading} disabled={loading} title="Sync Customers" clickFunc={syncOffice24SevenCustomers} type="button" />
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
};

export default RegisterUser;