import React, { useEffect, useState } from 'react';
import './Login.scss';
import * as Api from '../shared/Api';
import { saveState } from '../shared/LocalStorage';
import { FrontEndPoints, GetEndpoint, LoginEndPoints } from '../shared/Common';
import { loginUser } from '../context/Actions';
import { useAuthDispatch } from '../context/Context';
import { Link, Navigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import DknInput from '../dkn-input/DknInput';
import DknBtn from '../dkn-btn/DknBtn';
import DknHeading from '../dkn-heading/DknHeading';

export const Login = (props: any) => {

    const [loginError, setLoginError] = useState("");

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [endPoint, setEndPoint] = useState(GetEndpoint("login"));
    const [credentialsValid, setCredentialsValid] = useState(false);
    const [isAD, setIsAD] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useAuthDispatch();
    const [failCount, setFailCount] = useState(0);
    const [captchaValue, setCaptchaValue] = useState("");
    const [capRefObj, setCapRefObj] = useState() as any;

    const limit = 5;

    const validateUser = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setLoginError("");
        setLoading(true);
        Api.post(endPoint.credentialsEndpoint, { userName: userName, password: password, captchaKey: captchaValue }).then(response => {
            setLoading(false);
            if (response && response.status == 200) {
                saveState(response.data);
                loginUser(dispatch, response.data);
                setCredentialsValid(true);
            } else {
                resetRecaptcha();
                setFailCount(response.data.failCount);
                setLoginError("Error has occurred, please try again or contact administrator.");
            }
        }, err => {
            resetRecaptcha();
            setFailCount(err.response.data.failCount);
            setLoginError("Error has occurred, please try again or contact administrator.");
        });
    }

    function resetRecaptcha() {
        capRefObj.props.grecaptcha.reset();
        setCaptchaValue("");
        setLoading(false);
    }

    const onChange = (value: any) => {
        setCaptchaValue(value);
    }

    useEffect(() => {

        if (props && props.authyEndpoint && props.authyEndpoint.length) {
            setEndPoint(props);
        }

        Api.get("login/LoginErrorCount").then(response => {
            setFailCount(response.data);
        });

        if (window.location.href.includes("activeDirectory")) {
            !isAD && setIsAD(!isAD);
            if (!userName.length && !password.length) {
                //setUserName("mfa@dekon.no");
                //setPassword("MittPassord123!");
                //setUserName("muhammad.nadeem@dekon.no");
                //setPassword("Byttpassord123");
            }
        } else {
            isAD && setIsAD(!isAD);
            //if (!userName.length && !password.length) {
            //setUserName("muhammad.nadeem714@gmail.com");
            //setPassword("Nadeem123");
            //setUserName("martin.bohmer.hoyem@gmail.com");
            //setPassword("pt2bmc10");
            //}
        }

    }, []);

    if (credentialsValid) {
        return <Navigate to={FrontEndPoints.authOptions} state={endPoint} />
    }
    
    return (
        <div className="login">            
            <DknHeading title="Dekon SSO" description="Please write credentials to login here." />
            <div className="credentials-group">
                {loginError.length > 0 && <div className="login-error">{loginError}</div>}

                <form onSubmit={(e) => validateUser(e)}>
                    <div>
                        <DknInput dknId="usr" dknLabel="Username" dknPlaceholder="Enter email" changeFunc={setUserName} dknType="text" value={userName} />
                        <DknInput dknId="pwd" dknLabel="Password" dknPlaceholder="******" changeFunc={setPassword} dknType="password" value={password} />

                        <div className="captcha-parent" style={failCount > limit ? {} : { display: 'none' }} >
                            <ReCAPTCHA ref={(el:any) => { setCapRefObj(el); }} sitekey="6Les4ioaAAAAAAE027GKw8fHo8-7doFMhfZ1FMIX" onChange={onChange} />
                        </div>
                    </div>

                    <div style={{ marginTop: "16px" }}>
                        <div style={{ marginRight: "16px", display: "inline-block" }}>
                            <DknBtn title="Login" type="submit" disabled={loading || (failCount > limit && captchaValue != undefined && captchaValue.length == 0)} loading={loading} />
                        </div>
                        {!isAD && <Link to={FrontEndPoints.forgotPassword} className="forgot-password">Forgot password?</Link>}
                    </div>
                </form>
            </div>

        </div>
    );

}