import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FrontEndPoints, GetChangePasswordFeedback } from '../shared/Common';
import * as Api from '../shared/Api';
import './ForgotPassword.scss';
import history from "../shared/history";
import DknHeading from '../dkn-heading/DknHeading';
import DknInput from '../dkn-input/DknInput';
import DknBtn from '../dkn-btn/DknBtn';

const ForgotPassword = (props: any) => {

    const [email, setEmail] = useState("");
    const [isPasswordChanged, setIsPasswordChanged] = useState("");
    const [isError, setIsError] = useState("");
    const [loading, setLoading] = useState(false);

    const sendEmail = (): void => {
        if (!email.length || !email.length) {
            setIsError("Please write email correctly.");
            return;
        }

        setLoading(true);
        setIsError("");

        Api.post("login/ResetPasswordWithToken", { email: email }).then(response => {
            setLoading(false);
            console.log(response);
            setIsError("");
            setIsPasswordChanged("An email has been sent to your registered email address with reset password link.");
        }, err => {
            setLoading(false);
            console.log(err);
            setIsPasswordChanged("");
            setIsError(GetChangePasswordFeedback(err.response.status));
        });
    }

    return (
        <div className="forgot-password">

            <DknHeading title="Forgot password?" description="Provide your email. we will send you an email containing a link." />

            {isError && <h6 className="email-error">{isError}</h6>}

            <DknInput dknId="email" dknLabel="Enter your email address" dknPlaceholder="put@something.here" changeFunc={val => setEmail(val)} dknType="email" value={email} />

            <div style={{ marginTop: "16px" }}><DknBtn disabled={loading} loading={loading} title="Reset password" type="button" clickFunc={() => sendEmail()} /></div>

            { isPasswordChanged.length > 0 &&
                <><h6 className="email-success">{isPasswordChanged}</h6>
                    <Link to={FrontEndPoints.login}>Bring me to login page</Link>
                </>}
        </div>
    );
}

export default ForgotPassword;