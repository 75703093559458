import * as React from 'react';
import { Login } from '../../login/Login';
import { GetEndpoint, LoginEndPoints } from '../../shared/Common';


export const AdLogin = () => {

    const loginProps: LoginEndPoints = GetEndpoint("activeDirectory");

    return (
        <Login {...loginProps} />
    );
}