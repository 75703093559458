import { LocalData } from "./LocalStorage";

export enum ServiceAvailability {
    undefined,
    available,
    notAvailable,
    notFound //when service is not registered in DB. anynomous service
}

export enum ErrorCodes {
    ok = 200,
    badRequest400 = 400,
    unAuthorized401 = 401,
    forbidden403 = 403,
    inCompleteCredentials406 = 406,
    preConditionFailed409 = 409,
    userNotFound412 = 412,
    emailNotConfirmed417 = 417,
    serverError500 = 500,
    serverException502 = 502,
}

export enum SmartStoreRegistrationCodes {
    ok = 200,
    badRequest = 400,
    unAuthorized = 401,
    userNotFound = 406,
    appMappingFound = 409,
    userAlreadyExists = 422,
    serverError = 500,
    serverException = 502,
}

export enum UserStep {
    authyWaiting,
    emailCodeInput,
    emailCodeVerification,
    registerationSuccess,
    none,
    updated,
    updatedWithoutEmailChange
}
export enum AuthType {
    authy,
    email
}

export enum ValidationErrorCodeType {
    invalidForm,
}

export const GetChangePasswordFeedback = (code: SmartStoreRegistrationCodes) => {
    switch (code) {
        case SmartStoreRegistrationCodes.ok:
            return "Email is confirmed & Password is changed successfully!";
        case SmartStoreRegistrationCodes.badRequest:
            return "There is an error in changing password. please try again!";
        case SmartStoreRegistrationCodes.unAuthorized:
            return "User is not authorized to make changes!";
        case SmartStoreRegistrationCodes.userNotFound:
            return "User not Found!"
        default:
            return "There is an error in changing password. please try again!";
    }
}

export const GetLoginError = (errorCode: number = 200): string => {
    switch (errorCode) {
        case ErrorCodes.badRequest400:
            return "Invalid Credentials, try again";
        case ErrorCodes.unAuthorized401:
            return "Invalid Credentials, try again";
        case ErrorCodes.forbidden403:
            return "Email already exists with another user";
        case ErrorCodes.inCompleteCredentials406:
            return "Invalid Credentials, Write complete username(with domain). abs@abc.abc";
        case ErrorCodes.preConditionFailed409:
            return "User/Email already exists. Login or Register with another email";
        case ErrorCodes.userNotFound412:
            return "User not Found";
        case ErrorCodes.emailNotConfirmed417:
            return "Please check your email and confirm your account first.";
        case ErrorCodes.serverError500:
            return "Something bad happened, please try again";
        case ErrorCodes.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
}

export const GetValidationError = (errorCode: ValidationErrorCodeType): string => {
    switch (errorCode) {
        case ValidationErrorCodeType.invalidForm:
            return "Invalid Value/Values, Complete form and try again";
        default:
            return "";
    }
}

export const GetAuthError = (errorCode: number = 200, param: string = "", authyType: AuthType = AuthType.authy): string => {
    switch (errorCode) {
        case ErrorCodes.badRequest400:
            return authyType == AuthType.authy ? `Something went wrong, please try again or use other authentication option.${param}` : `Code didn't match, please try again or resend email`;
        default:
            return `Something went wrong, please try again or use other authentication option.${param}`;
    }
}

export const GetUserStepCaption = (type: UserStep = UserStep.none): string => {
    switch (type) {
        case UserStep.authyWaiting:
            return "Check your mobile with registered number and approve the authy authentication request.";
        case UserStep.emailCodeInput:
            return "Email has been sent, please check email and write code here to Verify";
        case UserStep.emailCodeVerification:
            return "";
        case UserStep.registerationSuccess:
            return "Registration was successful! please check your email to confirm account.";
        case UserStep.updated:
            return "Update was successful! please check your email to confirm account.";
        case UserStep.updatedWithoutEmailChange:
            return "Update was successful!";
        default:
            return "";
    }
}

export interface LoginEndPoints {
    authyEndpoint: string,
    credentialsEndpoint: string,
    emailEndpoint: string,
    optCodeEndpoint: string
}

export const GetEndpoint = (controllerName: string): LoginEndPoints => {
    return {
        authyEndpoint: `${controllerName}/ContinueWithAuthy`,
        credentialsEndpoint: `${controllerName}/CheckCredentials`,
        emailEndpoint: `${controllerName}/ContinueWithEmail`,
        optCodeEndpoint: `${controllerName}/VerifyOptCode`
    } as LoginEndPoints;
}

export const FrontEndPoints = {
    register: "/activeDirectory/newUser",
    login: "/login",
    authOptions: "/authOptions",
    logout: "/logout",
    activeDirectory: "/activeDirectory",
    smartStore: "/activeDirectory/smartStoreMappings",
    confirmEmail: "/activeDirectory/confirmEmail",
    forgotPassword: "/account/forgotPassword",
    resetPassword: "/account/resetPassword",
    addApplication: "/addApplication",
    updateRole: "/updateRole",
    usersOverview: "/usersOverview",
    generateToken: "/generateToken",
    all: "/"
}

export const FrontEndRoles = {
    Admin: "Administrator",
    User: "User",
    TemporaryAdminUser: "TemporaryAdminUser",
    TemporaryInternalUser: "TemporaryInternalUser",
    TemporaryUser: "TemporaryUser"
}

export const ValidateIsAdmin = (param: LocalData): boolean => {
    return (param && param.isSignedin && param.roles.includes(FrontEndRoles.Admin));
}
export const ValidateIsUser = (param: LocalData): boolean => {
    return (param && param.isSignedin);
}
export const ValidateTemperoryUser = (param: LocalData): boolean => {
    return (param && !param.isSignedin && (param.roles.includes(FrontEndRoles.TemporaryAdminUser) || param.roles.includes(FrontEndRoles.TemporaryUser) || param.roles.includes(FrontEndRoles.TemporaryInternalUser)));
}

export const MapPath = (): string => {
    var pathName = window?.location.pathname;
    switch (pathName) {
        case FrontEndPoints.register:
            return FrontEndPoints.register;
        case FrontEndPoints.login:
            return FrontEndPoints.login;
        case FrontEndPoints.authOptions:
            return FrontEndPoints.authOptions;
        case FrontEndPoints.activeDirectory:
            return FrontEndPoints.activeDirectory;
        case FrontEndPoints.smartStore:
            return FrontEndPoints.smartStore;
        case FrontEndPoints.addApplication:
            return FrontEndPoints.addApplication;
        case FrontEndPoints.confirmEmail:
            return FrontEndPoints.confirmEmail + window.location.search;
        case FrontEndPoints.forgotPassword:
            return FrontEndPoints.forgotPassword;
        case FrontEndPoints.resetPassword:
            return FrontEndPoints.resetPassword + window.location.search;
        case FrontEndPoints.usersOverview:
            return FrontEndPoints.usersOverview;
        default:
            return FrontEndPoints.all;
    }

}
export const ToLocalDate = (dateTicks: number): string => {
    if (dateTicks) {

        var date = new Date(dateTicks);
        var result = `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
        return result;
    }
    return "";
}