import React, { useEffect, useState } from 'react';
import './Application.scss';
import * as Api from '../shared/Api';
import Config from '../shared/Config';
import { saveState } from '../shared/LocalStorage';
import { AuthOptions } from '../authOptions/AuthOptions';
import { ErrorCodes, FrontEndPoints, GetEndpoint, GetLoginError, LoginEndPoints } from '../shared/Common';
import { loginUser } from '../context/Actions';
import { useAuthDispatch } from '../context/Context';
import { Link } from 'react-router-dom';
import { DknSelectValue, DknSelect } from '../dkn-select/DknSelect';
import DknHeading from '../dkn-heading/DknHeading';
import DknInput from '../dkn-input/DknInput';
import DknBtn, { DknBtnModel } from '../dkn-btn/DknBtn';

export enum ApplicationTypes {
    startstore,
    jasper,
    Office365
}

export const Application = (props: any) => {
    const initialState = { userName: "", applicationName: "", baseUrl: "", path: "", type: -1 };

    const [data, setData] = useState(initialState);
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const resetStatuses = () => {
        setError("");
        setStatus("");
    }

    const addApplication = () => {
        resetStatuses();
        Api.post("home/addApplication", data).then(res => {
            if (res.status === ErrorCodes.ok) {
                setStatus("Added application");
                setData(initialState);
            } else {
                setError("Failed adding application");
            }

        }, err => {
            if (err.response.status === ErrorCodes.preConditionFailed409) {
                setError("Application name already exists");
            } else {
                setError("Failed adding application");
            }
        });
    }

    const syncApplications = () => {
        setLoading(true);
        resetStatuses();
        Api.get("home/syncApplications").then(response => {
            setLoading(false);
            if (response.status === 200) {
                setStatus("Applications synced from config");
            } else if (response.status == 204) {
                setError("No new application to be synced.");
            } else {
                setError("There was an error, try again.");
            }
        },
            err => {
                setLoading(false);
                setError("There was an error, try again.");
            });
    }

    const onSelectedType = (selected: DknSelectValue) => {
        setData({ ...data, type: selected.value });
    }

    return (
        <div className="application">


            <DknHeading title="Applications administration" description="Add application here. This operation is seldom nessecary, only with new customers. Will be more often relevant with ASP than any other customers." />

            {/* <hr />*/}
            <div className="api-success-text center">
                {status}
            </div>

            <div className="api-error-text center">
                {error}
            </div>

            {(error.length > 0 || status.length > 0) && <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <hr />
                </div>
                <div className="col-md-4">
                </div>
            </div>}

            <DknBtn type="button" clickFunc={syncApplications} title="Sync from Configuration" disabled={loading} loading={loading} />

            <div className="alternate-option">
                -- Or --
            </div>

            <DknSelect data={[{ label: "Smartstore", value: ApplicationTypes.startstore }, { label: "Jasper", value: ApplicationTypes.jasper }, { label: "Office 365", value: ApplicationTypes.Office365}]} onSelected={onSelectedType} label="Application type" />
            {!data || data.type !== ApplicationTypes.Office365 && <>
            <DknInput dknType="text" dknId="applicationName" dknPlaceholder="Example: Dekon ASP - Lekia Asker - 10034" dknLabel="Application name" value={data.applicationName}
                changeFunc={(e: any) => setData({ ...data, applicationName: e })} />

            <DknInput dknType="text" dknId="baseUrl" dknPlaceholder="Example: http://192.168.168.213:10034, Example without port: http://192.168.168.213" dknLabel="Base URL" value={data.baseUrl}
                changeFunc={(e: any) => setData({ ...data, baseUrl: e })} />

            <DknInput dknType="text" dknId="path" dknPlaceholder="Example: /smartstore2" dknLabel="Path" value={data.path}
                changeFunc={(e: any) => setData({ ...data, path: e })} />
            </>}
            <div style={{ marginTop: "16px" }}>
                <DknBtn type="button" clickFunc={addApplication} title="Register" disabled={loading} loading={loading} />
            </div>
        </div>
    );

}