const authSessionKey = "auth_key";

export interface LocalData {
    isSignedin: boolean,
    token: string,
    roles: Array<string>
}

export const saveState = (data: LocalData): void => {
    let strData: any = null;
    if (data) {
        strData = JSON.stringify(data);
    }
    sessionStorage.setItem(authSessionKey, strData);
}

export const removeState = (): void => {   
    sessionStorage.removeItem(authSessionKey);
}

export const loadState = (): LocalData | undefined => {
    const data: any = sessionStorage.getItem(authSessionKey);
    if (data === null) {
        return undefined;
    }
    return JSON.parse(data);
};