import * as React from 'react';
import { useEffect, useState } from 'react';
import './UpdateRole.scss';
import * as Api from '../shared/Api';
import Select from 'react-select';
import DknHeading from '../dkn-heading/DknHeading';
import { DknSelect } from '../dkn-select/DknSelect';
import DknBtn from '../dkn-btn/DknBtn';

export const UpdateRole = (props: any) => {

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [users, setUsers] = useState() as any;
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState() as any;
    const [formVals, setFormVals] = useState() as any;

    useEffect(() => {
        ApiCall("Update/GetUsers", "users");
        ApiCall("Update/GetRoles", "roles");
    }, []);

    const ApiCall = (path: string, errorFiller: string): void => {
        Api.get(path).then(res => {
            if (res.status === 200) {
                    var result = res.data.map((d: any) => {
                        return {
                            label: d.label,
                            value: d.value
                        }
                    });
                if (errorFiller == "users") {
                    setUsers(result);
                } else {
                    setRoles(result);
                }
            } else {
                setError("Could not load " + errorFiller);
            }
        }, err => {
            setError("Could not load " + errorFiller);
        });

    }

    const handleUserChange = (param: any) => {
        setFormVals({ ...formVals, Label: param.value });
    }
    const handleRoleChange = (param: any) => {
        setFormVals({ ...formVals, Value: param.value });
    }
    const updateRole = () => {
        setLoading(true);
        Api.post("update/UpdateRole", formVals).then(response => {
            setLoading(false);
            if (response.status == 200) {
                setError("");
                setSuccess("user role updated");
            } else {
                setError("Error occured while updating user role");
            }
        }, err => {
            setLoading(false);
            setSuccess("");
            if (err.response.status == 409) {
                setError("Role already exists for this user");
            } else {
                setError("Error occured while updating user role");
            }
        });

    }

    return (
        <div className="update-user">
            <h3>Update user role</h3>
            <DknHeading title="Update user role" description="Admins can change the role of a user from pre defined roles. New roles cannot be added." />

            { error.length > 0 && <div className="api-error-text">{error}</div>}
            { success.length > 0 && <div className="api-success-text">{success}</div>}

            <DknSelect data={users} onSelected={handleUserChange} label="Select user" />

            <DknSelect data={roles} onSelected={handleRoleChange} label="Select the user's new role" />

            <div style={{ marginTop: "16px" }}><DknBtn loading={loading} disabled={loading} title="Update" type="button" clickFunc={() => updateRole()} /></div>
        </div>
    );

}