import * as React from 'react';
import {  Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from '../context/Context';
import { FrontEndPoints, ValidateIsUser } from './Common';
import history from '../../components/shared/history';

const PrivateRoute = () => {
    const userDetails: any = useAuthState();
    const location = useLocation();
    
    return ValidateIsUser(userDetails) ? <Outlet /> : <Navigate to={FrontEndPoints.login} replace state={{ from: location }} />
}

export default PrivateRoute;