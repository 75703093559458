import * as React from 'react';
import { useEffect, useState } from 'react';
import './DknSelect.scss';
import Select from 'react-select';

export interface DknSelectProps {
    data: Array<DknSelectValue>;
    label: string;
    onSelected: (selectedVal: DknSelectValue) => void;
    selectedValue?: DknSelectValue;
    isMulti?: boolean;
}

export interface DknSelectValue {
    label: string;
    value: any;
}

export const DknSelect = (props: DknSelectProps) => {

    const [selVal, setSelVal] = React.useState(props.selectedValue as any);

    const handleApplicationChange = (val: any) => {
        setSelVal(val);
        props.onSelected(val as DknSelectValue);
    }

    useEffect(() => {
        setSelVal(props.selectedValue);
    }, [props.selectedValue]);

    return (
        <div className="dkn-select form-group">
            <p className="dkn-label">{props.label}</p>
            <Select id="dknSelect" classNamePrefix="dkn-select" isMulti={props.isMulti} className="dkn-select" options={props.data} onChange={handleApplicationChange} value={selVal} />
        </div>
    );
}
